import { OriginalPaymentStatusEnum } from '@enums/disputes.enum';
import { NReport } from '@interfaces/components/report';
import { convertToTitleCase, getLocalImageUrl } from '@utils/resource';
import { Col, Row, Typography } from 'antd';
import { formatPriceBaseOnCurrency } from 'common/functions/format-price';
import { useTranslation } from 'react-i18next';

import TooltipWithCopy from '../../tooltip-with-copy';
import styles from '../styles.module.scss';

interface IOriginalPayment {
    title: string;
    amount: number;
    originalPayment: NReport.IOriginalPayment;
    formatDate: (dateValue: string) => string;
}

const { Text } = Typography;

const OriginalPayment = ({ title, originalPayment, formatDate, amount }: IOriginalPayment) => {
    const { t } = useTranslation(['common']);
    return (
        <>
            <div className={styles.headerColCustom}>
                <Typography.Title level={3}>
                    <Typography.Title level={3}>{title}</Typography.Title>
                </Typography.Title>
                <div className="m-2">
                    <span className="cursor-pointer">
                        <TooltipWithCopy text={originalPayment.id} label="ID" />
                    </span>
                    <span className="cursor-pointer">
                        <TooltipWithCopy text={originalPayment.traceId} label="Trace ID" />
                    </span>
                </div>
            </div>
            <Row gutter={[24, 24]} justify="space-between">
                <Col xs={24} sm={12} lg={6}>
                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.amount', { ns: 'common' })}</Text>
                        <Text strong className={styles.disputeBlock}>
                            {formatPriceBaseOnCurrency(amount || 0)}{' '}
                            {t(`disputes.currency.usd`, { ns: 'common' })}
                        </Text>
                    </div>
                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.fees', { ns: 'common' })}</Text>
                        <Text strong className={styles.disputeBlock}>
                            {formatPriceBaseOnCurrency(originalPayment?.fee || 0)}{' '}
                            {t(`disputes.currency.usd`, { ns: 'common' })}{' '}
                        </Text>
                    </div>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.state', { ns: 'common' })}</Text>
                        <span className={`${styles.disputeBlockFlex}`}>
                            <span>
                                {originalPayment?.state?.toLowerCase() ===
                                    OriginalPaymentStatusEnum.SUCCEEDED.toLowerCase() && (
                                    <img
                                        src={'/assets/images/enable.svg'}
                                        alt="more"
                                        className={`cursor-pointer w-4 mr-1`}
                                    />
                                )}
                            </span>
                            {convertToTitleCase(originalPayment?.state ?? '')}
                        </span>
                    </div>
                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.created_at', { ns: 'common' })}</Text>
                        <Text strong className={styles.disputeBlock}>
                            {formatDate(`${originalPayment?.createdAt}`)}
                        </Text>
                    </div>
                </Col>
                <Col xs={24} sm={12} lg={7}>
                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.type_original_payment', { ns: 'common' })}</Text>
                        <Text strong className={styles.disputeBlock}>
                            {convertToTitleCase(originalPayment?.type ?? '') ?? '-'}
                        </Text>
                    </div>
                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.statement_descriptor', { ns: 'common' })}</Text>
                        <Text strong className={styles.disputeBlock}>
                            {originalPayment?.statementDescriptor ?? '-'}
                        </Text>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default OriginalPayment;
