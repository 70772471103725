import { IssuerCountryDisputeEnum, StateInstrumentEnum } from '@enums/disputes.enum';
import { NReport } from '@interfaces/components/report';
import { convertToTitleCase, getLocalImageUrl } from '@utils/resource';
import { Col, Row, Typography } from 'antd';
import { upperCase } from 'lodash';
import { useTranslation } from 'react-i18next';

import { styleCardBrand } from '../..';
import TooltipWithCopy from '../../tooltip-with-copy';
import styles from '../styles.module.scss';
interface IPaymentInstrument {
    title: string;
    instrument: NReport.IPaymentInstrument;
    formatDate: (dateValue: string) => string;
    createdVia?: string;
}

const { Text } = Typography;

const PaymentInstrument = ({ title, instrument, formatDate, createdVia }: IPaymentInstrument) => {
    const { t } = useTranslation(['common']);

    const getIssuerCountry = (country?: string) => {
        if (!country) return '-';

        switch (country) {
            case IssuerCountryDisputeEnum.NON_USA:
                return IssuerCountryDisputeEnum.INTERNATIONAL;
            case IssuerCountryDisputeEnum.US:
            case IssuerCountryDisputeEnum.USA:
                return IssuerCountryDisputeEnum.UNITED_STATES;
            default:
                return country;
        }
    };
    return (
        <>
            <div className={styles.headerColCustom}>
                <Typography.Title level={3}>
                    <Typography.Title level={3}>{title}</Typography.Title>
                </Typography.Title>
                <div className="m-2">
                    <span className="cursor-pointer">
                        <TooltipWithCopy text={instrument.id} label="ID" />
                    </span>
                    <span className="cursor-pointer">
                        <TooltipWithCopy
                            text={instrument.fingerprint}
                            label={t('disputes.finger_print', { ns: 'common' })}
                        />
                    </span>
                </div>
            </div>
            <Row gutter={[24, 24]} justify="space-between">
                <Col xs={24} sm={12} lg={6}>
                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.payment_instrument_state', { ns: 'common' })}</Text>
                        <Text strong className={styles.disputeBlock}>
                            {instrument?.state ? (
                                <span className={styles.disputeFlexRow}>
                                    <img
                                        src={'/assets/images/enable.svg'}
                                        alt="more"
                                        className={`cursor-pointer w-4`}
                                    />
                                    <span className="pl-1">{StateInstrumentEnum.ENABLE}</span>
                                </span>
                            ) : (
                                <>{StateInstrumentEnum.DISABLE}</>
                            )}
                        </Text>
                    </div>
                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.created_at', { ns: 'common' })}</Text>
                        <Text strong className={styles.disputeBlock}>
                            {formatDate(`${instrument?.createdAt}`)}
                        </Text>
                    </div>
                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.name', { ns: 'common' })}</Text>
                        <Text strong className={styles.disputeBlock}>
                            {instrument?.name || '-'}
                        </Text>
                    </div>
                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.card_type_instrument', { ns: 'common' })}</Text>
                        <Text strong className={styles.disputeBlock}>
                            {convertToTitleCase(instrument?.type || '') || '-'}
                        </Text>
                    </div>
                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.created_via', { ns: 'common' })}</Text>
                        <Text strong className={styles.disputeBlock}>
                            {createdVia || '-'}
                        </Text>
                    </div>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.card_brand', { ns: 'common' })}</Text>
                        <Text strong className={styles.disputeBlock}>
                            {convertToTitleCase(instrument?.brand || '') ?? '-'}
                        </Text>
                    </div>
                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.card_type', { ns: 'common' })}</Text>
                        <Text strong className={styles.disputeBlock}>
                            {convertToTitleCase(instrument?.cardType || '') ?? '-'}
                        </Text>
                    </div>
                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.masked_number', { ns: 'common' })}</Text>
                        <div className="flex flex-row items-center ">
                            <span className="pr-1">{styleCardBrand(instrument.brand ?? '-')}</span>
                            <Text strong className={styles.disputeBlock}>
                                {instrument?.bin && instrument.lastFour
                                    ? `${instrument.bin} •••••• ${instrument.lastFour}`
                                    : '-'}
                            </Text>
                        </div>
                    </div>
                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.expiration_date', { ns: 'common' })}</Text>
                        <Text strong className={styles.disputeBlock}>
                            {instrument?.expirationMonth}/{instrument?.expirationYear}
                        </Text>
                    </div>
                </Col>
                <Col xs={24} sm={12} lg={7}>
                    <div className="flex justify-between items-center">
                        <Text>{t('disputes.cvv_verification', { ns: 'common' })}</Text>
                        <Text strong className={styles.disputeBlock}>
                            {convertToTitleCase(instrument?.securityCodeVerification || '') ?? '-'}
                        </Text>
                    </div>
                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.address', { ns: 'common' })}</Text>
                        <Text strong className={styles.disputeBlock}>
                            {upperCase(instrument?.address || '') ?? '-'},
                        </Text>
                    </div>
                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.address_verification', { ns: 'common' })}</Text>
                        <Text strong className={styles.disputeBlock}>
                            {convertToTitleCase(instrument?.addressVerification || '') ?? '-'}
                        </Text>
                    </div>

                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.issuer_country', { ns: 'common' })}</Text>
                        <Text strong className={styles.disputeBlock}>
                            {convertToTitleCase(getIssuerCountry(instrument.issuerCountry))}
                        </Text>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default PaymentInstrument;
