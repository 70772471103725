import Response from '@data-access/responses/response';
import { WLSetting } from '@interfaces/white-label-setting';
import { useApiUrl, useCustom, useCustomMutation, useOne } from '@refinedev/core';
import { notification, Spin } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FinixPaymentGateway } from '../finix';
import s from './styles.module.scss';

export const WLPayoutsPage = () => {
    const { t } = useTranslation('management');
    const apiUrl = useApiUrl();
    const { mutate: customMutation, isLoading: isLoadingUpdate } =
        useCustomMutation<Response<boolean>>();

    const {
        data: isAlreadyOnboarded,
        isLoading,
        refetch,
    } = useCustom<Response<boolean>>({
        url: `${apiUrl}/v1/payments/finix/merchant`,
        method: 'get',
        queryOptions: {
            enabled: false,
        },
    });

    const {
        data: wlPayoutSetting,
        isLoading: isLoadingWLPayoutSetting,
        refetch: refetchWLPayoutSetting,
    } = useOne<WLSetting.IWLPayoutSetting>({
        resource: 'v1/user-payments/setting',
        id: '',
        queryOptions: {
            enabled: false,
        },
    });

    useEffect(() => {
        refetch();
        refetchWLPayoutSetting();
    }, []);

    const updateUserPaymentSetting = async (request: {
        merchantId: string;
        data?: {
            [name: string]: string;
        };
    }) => {
        customMutation(
            {
                url: `${apiUrl}/v1/user-payments`,
                method: 'put',
                values: request,
            },
            {
                onSuccess: (data, _, __) => {
                    if (data && data.data?.isSuccess) {
                        notification.success({
                            className: 'success-notification',
                            message: '',
                            description: t('update_success'),
                        });
                        refetch();
                        refetchWLPayoutSetting();
                    } else {
                        notification.error({
                            className: 'error-notification',
                            message: '',
                            description: t('update_err'),
                        });
                    }
                },
            },
        );
    };
    const isOverallLoading = isLoading || isLoadingWLPayoutSetting || isLoadingUpdate;
    return (
        <Spin spinning={isOverallLoading}>
            <section className={`flex items-center justify-center ${s.employeePayoutsPage}`}>
                {!isOverallLoading && isAlreadyOnboarded && (
                    <div className="payment-setting-content p-6 w-full">
                        <FinixPaymentGateway
                            wlPayout={wlPayoutSetting?.data}
                            onSubmit={updateUserPaymentSetting}
                            isSuccessOnboarding={isAlreadyOnboarded?.data.data}
                        />
                    </div>
                )}
            </section>
        </Spin>
    );
};
