import './styles.scss';

import { CustomSearch } from '@components/modules/custom-search';
import { IInvoice } from '@interfaces/invoice';
import { NPDFMakeSetting } from '@interfaces/pdf-make.setting';
import { useList, useTable } from '@refinedev/core';
import { Table, Tabs, Tooltip } from 'antd';
import { DataProviderNameEnum } from 'dataProvider';
import { Content } from 'pdfmake/interfaces';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { InvoiceSubscriptionEnum } from '../../../../enums/invoice-subscription-status.enum';
import { IMarketplace } from '../../../../interfaces/marketplace';
import { ISubscription } from '../../../../interfaces/subscription';
import {
    formatAppSubscriptionPeriodEnd,
    RECURRING_SUBSCRIPTION_SUBTRACT_DAY,
} from '../../../../utils/date';
import { convertPrice } from '../../../../utils/resource';
import { Chip } from '../../../modules/chip';
import { CustomPagination } from '../../../modules/pagination';
import { DownloadPrint } from '../download-pdf';
import { ContactInvoiceTabContent } from './contact-invoice-list';
import { renderInvoiceSummary, renderInvoiceTable } from './contact-invoice-list/print-pdf-invoice';

enum TabsEnum {
    INVOICES = 'contactInvoices',
    SUBSCRIPTION_INVOICES = 'contactSubscriptionInvoices',
}
export const PAGE_SIZE = 5;
export const ContactCrmInvoicesPayments = ({
    contactId,
    contactName,
}: {
    contactId: string;
    contactName: string;
}) => {
    const { t } = useTranslation(['common', 'contact', 'quote']);

    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;
    const { id } = useParams();
    const [tab, setTab] = useState<TabsEnum>(TabsEnum?.INVOICES);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [filter, setFilter] = useState<string>('');
    const [dataToPrint, setDataToPrint] = useState<NPDFMakeSetting.IReportBody>();
    const [invoiceData, setInvoiceData] = useState<IInvoice.Invoice[]>();

    const onChangeTab = (e: TabsEnum) => {
        setTab(e);
    };

    const {
        data: tableQueryInvoiceResult,
        refetch: invoiceRefetch,
        isLoading: invoiceLoading,
    } = useList<IInvoice.Invoice>({
        dataProviderName,
        resource: 'v1/contacts/get-invoices',
        queryOptions: {
            enabled: false,
        },
        config: {
            hasPagination: false,
            filters: [
                {
                    field: 'contactId',
                    operator: 'eq',
                    value: contactId,
                },
                {
                    field: 'pageIndex',
                    operator: 'eq',
                    value: pageIndex,
                },
                {
                    field: 'pageSize',
                    operator: 'eq',
                    value: PAGE_SIZE,
                },
                {
                    field: 'filter',
                    operator: 'eq',
                    value: filter,
                },
            ],
            sort: [
                {
                    field: 'incrementId',
                    order: 'desc',
                },
            ],
        },
    });

    const {
        tableQueryResult: contactSubscriptionData,
        setPageSize,
        setCurrent,
        current,
        pageSize,
    } = useTable<ISubscription.SubscriptionList>({
        resource: 'v1/application-subscriptions/invoices/contact',
        dataProviderName,
        queryOptions: {
            retry: 1,
        },
        initialPageSize: 5,
        permanentFilter: [
            {
                field: 'contactId',
                operator: 'eq',
                value: id,
            },
            {
                field: 'invoiceIncrementId',
                operator: 'eq',
                value: filter,
            },
        ],
    });

    const renderStatus = (code: InvoiceSubscriptionEnum) => {
        const label = t(`invoices.statuses.${code}`, {
            ns: 'marketplace',
        });
        let type = '';
        switch (code) {
            //Paid
            case InvoiceSubscriptionEnum.PAID:
                type = 'disable';
                break;
            //Open
            case InvoiceSubscriptionEnum.OPEN:
                type = 'success';
                break;
            //default
            default:
                type = 'disable';
                break;
        }
        return <Chip {...{ label, type }} />;
    };

    useEffect(() => {
        if (contactId) {
            switch (tab) {
                case TabsEnum.INVOICES: {
                    invoiceRefetch();
                    break;
                }
                case TabsEnum.SUBSCRIPTION_INVOICES: {
                    if (filter) {
                        setCurrent(1);
                    }
                    contactSubscriptionData.refetch();
                    break;
                }
            }
        }
    }, [pageIndex, contactId, filter]);

    useEffect(() => {
        if (contactId) {
            setPageIndex(1);
            switch (tab) {
                case TabsEnum.INVOICES: {
                    invoiceRefetch();
                    break;
                }
            }
        }
    }, [tab]);

    useEffect(() => {
        let summaryData: Content[] = [];
        const tableData: Content[] = [];

        if (invoiceData) {
            summaryData = renderInvoiceSummary(invoiceData, (rs: string, obj: any) => t(rs, obj));
        }
        if (invoiceData?.length) {
            const title = '';
            const resultMapTable = renderInvoiceTable(invoiceData, (rs: string, obj: any) =>
                t(rs, obj),
            );
            tableData.push(title, resultMapTable);
        }

        const InvoiceData: NPDFMakeSetting.IReportBody = {
            summarySection: summaryData || [],
            tableBody: tableData || [],
        };

        setDataToPrint(InvoiceData);
    }, [invoiceData]);

    const searchElement = useMemo(() => {
        return (
            <div className="flex">
                <CustomSearch
                    placeholder={
                        tab === TabsEnum?.INVOICES
                            ? t('quotes.fields.search.invoce_placeholder', { ns: 'quote' })
                            : t('quotes.fields.search.recurring_invoice_placeholder', {
                                  ns: 'quote',
                              })
                    }
                    className={'search-item p-3'}
                    onChange={(event: any) => {
                        setFilter(event?.target.value);
                        setPageIndex(1);
                    }}
                    allowClear={true}
                />
                {tab === TabsEnum.INVOICES && (
                    <DownloadPrint
                        title={`${t('contacts.pdf.invoice.invoice_of', {
                            ns: 'contact',
                        })} ${contactName}`}
                        contactName={contactName}
                        dataToPrint={dataToPrint}
                    />
                )}
            </div>
        );
    }, [tab, dataToPrint]);

    return (
        <div className="contact-payments-invoices overflow-hidden">
            <Tabs
                tabBarExtraContent={searchElement}
                onChange={(e) => onChangeTab(e as TabsEnum)}
                defaultActiveKey={TabsEnum.INVOICES}
            >
                <Tabs.TabPane tab={t('contact.invoices', { ns: 'common' })} key={TabsEnum.INVOICES}>
                    <ContactInvoiceTabContent
                        cols={tableQueryInvoiceResult?.data || []}
                        current={pageIndex}
                        setCurrent={setPageIndex}
                        tableResult={tableQueryInvoiceResult}
                        pageSize={PAGE_SIZE}
                        setDataToPrint={(value: IInvoice.Invoice[]) => setInvoiceData(value)}
                        invoiceLoading={invoiceLoading}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={t('contact.subscription_invoice', { ns: 'common' })}
                    key={TabsEnum.SUBSCRIPTION_INVOICES}
                >
                    <div className="overflow-hidden">
                        <div className="list-content table-wrapper">
                            <Table
                                dataSource={contactSubscriptionData.data?.data}
                                pagination={false}
                                tableLayout="fixed"
                                scroll={{ x: '900px' }}
                                rowKey="id"
                                loading={contactSubscriptionData.isLoading}
                            >
                                <Table.Column
                                    title={<>{t('subscriptions.invoiceId', { ns: 'common' })}</>}
                                    dataIndex="invoiceIncrementId"
                                    key="invoiceIncrementId"
                                    width={100}
                                    render={(
                                        invoiceIncrementId,
                                        record: ISubscription.SubscriptionList,
                                        _,
                                    ) => {
                                        return (
                                            <Link
                                                to={`/sale/invoices/show/${record.invoiceId}`}
                                                className="table-tbody-text"
                                            >
                                                <u> # {invoiceIncrementId}</u>
                                            </Link>
                                        );
                                    }}
                                />
                                <Table.Column
                                    title={<>{t('subscriptions.statusTitle', { ns: 'common' })}</>}
                                    dataIndex="status"
                                    key="status"
                                    width={100}
                                    render={(status, _) => {
                                        return (
                                            <p className={`table-tbody-text status-${status}`}>
                                                {status ? renderStatus(status) : null}
                                            </p>
                                        );
                                    }}
                                />
                                <Table.Column
                                    title={<>{t('subscriptions.productName', { ns: 'common' })}</>}
                                    dataIndex="applicationTitle"
                                    key="applicationTitle"
                                    width={200}
                                    render={(applicationTitle, _) => {
                                        return (
                                            <p className="table-tbody-text">
                                                <Tooltip title={applicationTitle}>
                                                    {applicationTitle}
                                                </Tooltip>
                                            </p>
                                        );
                                    }}
                                />
                                <Table.Column
                                    title={
                                        <>
                                            {t('subscriptions.nextInvoiceDate', {
                                                ns: 'common',
                                            })}
                                        </>
                                    }
                                    dataIndex="periodEnd"
                                    key="periodEnd"
                                    width={150}
                                    render={(
                                        periodEnd,
                                        record: IMarketplace.ISubscriptionInvoice,
                                    ) => {
                                        return (
                                            <p className="table-tbody-text">
                                                {formatAppSubscriptionPeriodEnd(
                                                    RECURRING_SUBSCRIPTION_SUBTRACT_DAY,
                                                    periodEnd as Date,
                                                    record,
                                                )}
                                            </p>
                                        );
                                    }}
                                />
                                <Table.Column
                                    title={<>{t('subscriptions.price', { ns: 'common' })}</>}
                                    dataIndex="amount"
                                    key="amount"
                                    width={150}
                                    render={(amount, _) => {
                                        return (
                                            <p className="table-tbody-text">
                                                {convertPrice(amount)}
                                            </p>
                                        );
                                    }}
                                />
                                <Table.Column
                                    title={
                                        <>
                                            {t('subscriptions.frequencyTitle', {
                                                ns: 'common',
                                            })}
                                        </>
                                    }
                                    dataIndex="frequency"
                                    key="frequency"
                                    width={150}
                                    render={(frequency, _) => {
                                        return <p className="table-tbody-text">{frequency}</p>;
                                    }}
                                />
                            </Table>
                        </div>
                        <div className="pagination-container pt-3 pb-4 px-6">
                            <CustomPagination
                                pageSize={pageSize}
                                total={contactSubscriptionData?.data?.total}
                                current={current}
                                onChange={(value: number, size: number) => {
                                    setCurrent(value);
                                    setPageSize(size);
                                }}
                                showSizeChanger={false}
                                showLessItems
                            />
                        </div>
                    </div>
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
};
