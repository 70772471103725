import { DownloadPrint } from '@components/contact/download-pdf';
import { DateRangeFilter } from '@components/reports/settlements/date-range-filter';
import { CardTypeEnum, DisputeResponseStateEnum } from '@enums/disputes.enum';
import { ExportTypeEnum } from '@enums/export-file.enum';
import { convertToTitleCase, getLocalImageUrl, uppercaseAll } from '@utils/resource';
import { Space } from 'antd';
import { useFilterContext } from 'contexts/filter.context';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import { DisputesInformation } from './disputes-information';

const Disputes = () => {
    const { t } = useTranslation(['dashboard']);

    const timeZone = moment.tz.guess();

    const { filter, onSetDateRange } = useFilterContext();

    return (
        <section className="filter py-4 order-report-container">
            <div className="flex justify-between filter-wrapper-container">
                <Space size={16} direction="horizontal" wrap={true} className="mb-8">
                    <DateRangeFilter
                        dateRange={filter?.dateRange}
                        setDateRange={onSetDateRange}
                        reportType={ExportTypeEnum.DISPUTES}
                    />
                </Space>

                <DownloadPrint
                    title={t('disputes.title', { ns: 'dashboard' })}
                    dateRange={filter?.dateRange}
                    reportType={ExportTypeEnum.DISPUTES}
                    timeZone={timeZone}
                    isExportExcel
                />
            </div>

            <DisputesInformation filter={{ dateRange: filter?.dateRange }} timeZone={timeZone} />
        </section>
    );
};

export default Disputes;

export const styleCardBrand = (brand: string) => {
    if (!brand) return null;
    const _brand = convertToTitleCase(brand);
    switch (_brand) {
        case CardTypeEnum.VISA:
            return <img src={'/assets/images/card/visa.svg'} alt={CardTypeEnum.VISA} />;

        case CardTypeEnum.DISCOVER:
            return <img src={'/assets/mages/card/discover.svg'} alt={CardTypeEnum.DISCOVER} />;
        case CardTypeEnum.AMERICAN_EXPRESS:
            return (
                <img
                    src={'a/ssets/images/card/american_express.svg'}
                    alt={CardTypeEnum.AMERICAN_EXPRESS}
                />
            );
        default:
            return <img src={'/assets/images/card/mastercard.svg'} alt={CardTypeEnum.MASTERCARD} />;
    }
};

export const styleStatusState = (state: string) => {
    if (!state) return null;
    const _state = uppercaseAll(state);
    switch (_state) {
        case DisputeResponseStateEnum.PENDING:
            return (
                <img src={'/assets/images/schedule.svg'} alt={DisputeResponseStateEnum.PENDING} />
            );
    }
};
