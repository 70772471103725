import { Alert, Checkbox, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type IModalAcceptDispute = {
    setIsChecked: (value: boolean) => void;
    setNote: (value: string) => void;
    isChecked: boolean;
    note: string;
};

const ModalAcceptDispute = ({ setIsChecked, setNote, note, isChecked }: IModalAcceptDispute) => {
    const { t } = useTranslation(['dashboard']);
    return (
        <div>
            <p>{t('disputes.accepting_liability_modal_content', { ns: 'dashboard' })}</p>

            {/* Text Area for Note */}
            <Input.TextArea
                placeholder="Note"
                rows={3}
                value={note}
                style={{ marginBottom: 16 }}
                onChange={(event) => setNote(event.target.value)}
            />

            {/* Alert Message */}
            <Alert
                message={t('disputes.accepting_liability_modal_warning', { ns: 'dashboard' })}
                type="warning"
                showIcon
                className={styles.alertModal}
            />

            {/* Confirmation Checkbox */}
            <Checkbox checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)}>
                {t('disputes.accepting_liability_modal_confirm', { ns: 'dashboard' })}
            </Checkbox>
        </div>
    );
};

export default ModalAcceptDispute;
