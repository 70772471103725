import { CloseOutlined } from '@ant-design/icons';
import { Modal, notification } from 'antd';
import { FinixPayment } from 'pages/internal-crm/finix-payment-modal/finix-payment';
import { FC } from 'react';

import { FinixPaymentForm } from '../invoice/details';

type FinixPaymentModalProps = {
    showFinixPaymentForm: {
        open: boolean;
        secret: string;
        transactionId: string;
        invoiceId: string;
    };
    onChangeFinixPaymentForm: (value: FinixPaymentForm) => void;
    onHandleFinixPaymentSuccess?: () => void;
    isWL?: boolean;
};

export const FinixPaymentModal: FC<FinixPaymentModalProps> = (props) => {
    const { onChangeFinixPaymentForm, showFinixPaymentForm, onHandleFinixPaymentSuccess, isWL } =
        props;
    return showFinixPaymentForm?.open ? (
        <Modal
            centered
            closeIcon={<CloseOutlined />}
            footer={false}
            closable={true}
            open={showFinixPaymentForm.open}
            maskClosable={false}
            onOk={() => {
                onChangeFinixPaymentForm({
                    open: false,
                    secret: '',
                    transactionId: '',
                    invoiceId: '',
                });
            }}
            onCancel={() => {
                onChangeFinixPaymentForm({
                    open: false,
                    secret: '',
                    transactionId: '',
                    invoiceId: '',
                });
            }}
        >
            <FinixPayment
                identity={showFinixPaymentForm.secret}
                invoiceId={showFinixPaymentForm.invoiceId}
                transactionId={showFinixPaymentForm.transactionId}
                onClose={(result: boolean) => {
                    //close popup if success
                    onChangeFinixPaymentForm({
                        open: false,
                        secret: '',
                        transactionId: '',
                        invoiceId: '',
                    });

                    if (result) {
                        notification.success({
                            message: 'Payment sucess',
                        });
                        onHandleFinixPaymentSuccess && onHandleFinixPaymentSuccess();
                    }
                }}
                isWL={isWL}
            />
        </Modal>
    ) : (
        <></>
    );
};
