import './styles.scss';

import { useSelect } from '@refinedev/antd';
import { CrudFilters } from '@refinedev/core';
import { Empty, Form, FormInstance, Select, SelectProps, Spin } from 'antd';
import { DataProviderNameEnum } from 'dataProvider';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PartnerTypeEnum } from '../../../enums/partner-type.enum';
import { IPartner } from '../../../interfaces/partner';
import { IUser } from '../../../interfaces/user';

export const SelectPartnerCrm: React.FC<
    SelectProps & {
        isActivePartner?: boolean;
        selectedPartner?: IPartner.IPartnerInfo;
        getSelectedPartner?: (partner: IPartner.IPartnerInfo) => void;
        clearPartner?: () => void;
        type?: PartnerTypeEnum;
        hideDeactivated?: boolean;
        currentPartner?: IUser.IUserDto;
        form?: FormInstance;
        resetFilters?: () => void;
    }
> = (props) => {
    const { t } = useTranslation(['common']);
    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;

    const [pageIndex, setPageIndex] = useState(1);
    const [currentData, setCurrentData] = useState<IPartner.IPartnerInfo[]>([]);
    const {
        value,
        className,
        placeholder,
        selectedPartner,
        getSelectedPartner,
        clearPartner,
        type,
        hideDeactivated,
        currentPartner,
        form,
        ...res
    } = props;

    const [filters, setFilters] = useState<CrudFilters>([
        {
            field: 'isActive',
            operator: 'eq',
            value: props?.isActivePartner ? props.isActivePartner : '',
        },
        {
            field: 'type',
            operator: 'eq',
            value: type || '',
        },
        {
            field: 'hideDeactivated',
            operator: 'eq',
            value: hideDeactivated || false,
        },
    ]);

    const { selectProps, queryResult } = useSelect<IPartner.IPartnerInfo>({
        dataProviderName,
        resource: 'v1/partner/list',
        debounce: 500,
        onSearch: (value) => {
            setPageIndex(1);
            setCurrentData([]);
            setFilters([
                {
                    field: 'filter',
                    operator: 'eq',
                    value,
                },
                {
                    field: 'isActive',
                    operator: 'eq',
                    value: props?.isActivePartner ? props.isActivePartner : '',
                },
                {
                    field: 'hideDeactivated',
                    operator: 'eq',
                    value: hideDeactivated || false,
                },
            ]);
            return [];
        },
        sort: [
            {
                field: 'name',
                order: 'asc',
            },
        ],

        pagination: {
            current: pageIndex,
            mode: 'server',
        },
        filters: filters,
        queryOptions: {
            onSuccess: (data) => {
                setCurrentData(currentData.concat(data.data));
            },
        },
        errorNotification: (error, _, __) => {
            if ((error as { statusCode: number })?.statusCode === 403) {
                return {
                    type: 'error',
                    message: t('notifications.errors.not_enough_permission_partners'),
                };
            } else {
                return {
                    type: 'error',
                    message: t('notifications.errors.get_partners_failed'),
                };
            }
        },
    });

    const onChangePartner = (value: string) => {
        const partner = currentData.find((item) => item.id === value);
        partner && getSelectedPartner && getSelectedPartner(partner);
    };

    const resetFilters = () => {
        setPageIndex(1);
        setCurrentData([]);
        setFilters([
            {
                field: 'isActive',
                operator: 'eq',
                value: props?.isActivePartner ? props.isActivePartner : '',
            },
            {
                field: 'type',
                operator: 'eq',
                value: type || '',
            },
            {
                field: 'hideDeactivated',
                operator: 'eq',
                value: hideDeactivated || false,
            },
        ]);
    };

    const options = useMemo(() => {
        const initialOptions =
            currentData?.map((item) => ({
                value: item.id,
                label: `${item.firstName} ${item.lastName}`,
            })) || [];

        if (currentPartner) {
            const findPartner = currentData.find((item) => item.id === currentPartner.id);
            if (!findPartner) {
                initialOptions.push({
                    value: currentPartner.id,
                    label: `${currentPartner.firstName} ${currentPartner.lastName}`,
                });
            }
        }

        return initialOptions;
    }, [currentPartner, currentData]);

    console.log({ options, currentPartner, currentData });

    return (
        <Form form={form}>
            <Form.Item name="partner">
                <Select
                    getPopupContainer={(trigger) => trigger.parentNode}
                    {...selectProps}
                    notFoundContent={queryResult.isFetching ? <Spin size="small" /> : <Empty />}
                    placeholder="Select Partner"
                    {...props}
                    allowClear
                    className="select-partner"
                    defaultValue={
                        currentPartner
                            ? {
                                  value: currentPartner?.id || '',
                                  label:
                                      `${currentPartner?.firstName} ${currentPartner?.lastName}` ||
                                      '',
                              }
                            : null
                    }
                    onPopupScroll={async (e: any) => {
                        const { target } = e;
                        if (
                            (target as any).scrollTop + (target as any).offsetHeight ===
                            (target as any).scrollHeight
                        ) {
                            if (
                                queryResult.data &&
                                currentData.length < queryResult.data?.total &&
                                !queryResult.isRefetching &&
                                !queryResult.isFetching
                            ) {
                                setPageIndex(pageIndex + 1);
                                await queryResult.refetch();
                            }
                        }
                    }}
                    options={options}
                    loading={queryResult.isRefetching || queryResult.isFetching}
                    onClear={() => {
                        setPageIndex(1);
                        setCurrentData([]);
                        setFilters([]);
                        clearPartner && clearPartner();
                        if (!filters.length) {
                            queryResult.refetch();
                        }
                    }}
                    onChange={(value) => onChangePartner(value)}
                />
            </Form.Item>
        </Form>
    );
};
