import { notification, Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from '../disputes-detail/styles.module.scss';

interface ITooltipWithCopy {
    label: string;
    text: string;
}

const TooltipWithCopy = ({ label, text }: ITooltipWithCopy) => {
    const { t } = useTranslation(['dashboard', 'common']);

    const handleCopyCode = (code: string) => {
        navigator.clipboard?.writeText(code).then(
            () =>
                notification.success({
                    message: '',
                    description: t('disputes.copy_clipboard_success', { ns: 'dashboard' }),
                }),
            () =>
                notification.error({
                    message: '',
                    description: t('disputes.copy_clipboard_failed', { ns: 'dashboard' }),
                }),
        );
    };

    return (
        <Tooltip
            title={
                <div className={styles.customTooltip}>
                    {text}
                    <span className={styles.copyCode} onClick={() => handleCopyCode(text)}>
                        {t('disputes.click_to_copy', { ns: 'dashboard' })}
                    </span>
                </div>
            }
            trigger="hover"
            placement="right"
            overlayClassName={styles.customOverlay}
        >
            <Tag color="default">{label}</Tag>
        </Tooltip>
    );
};

export default TooltipWithCopy;
