import 'styles/common/global.scss';

import { NPayloadInvoice } from '@interfaces/payload-invoice';
import { dataProvider, Nuvioo } from '@nuvioo/core-ui';
import { getEnvConfig } from 'getEnvConfig';
import { useGetQueryValue } from 'hooks/useGetQueryValue';
import jwt_decode from 'jwt-decode';
import { ContactIndex } from 'pages/contact';
import { ExtraApplicationPlanIndex } from 'pages/extra-application-plan';
import { HelpdeskIndex } from 'pages/helpdesk';
import { HelpdeskCreate } from 'pages/helpdesk/create';
import { HelpdeskDetail } from 'pages/helpdesk/helpdesk-detail';
import { ContactCrmIndex } from 'pages/internal-crm/contact';
import { ContactDetailCrmPage } from 'pages/internal-crm/contact/detail';
import { InvoiceCrmIndex } from 'pages/internal-crm/invoice';
import { InvoiceCreateCrmIndex } from 'pages/internal-crm/invoice/create';
import { InvoiceDetailsCrmIndex } from 'pages/internal-crm/invoice/details';
import { InvoiceEditCrmIndex } from 'pages/internal-crm/invoice/edit';
import { PartnerCrmIndex } from 'pages/internal-crm/partner';
import { PayoutCrmLayoutPage } from 'pages/internal-crm/payout-setting';
import { PayoutReportCrmIndex } from 'pages/internal-crm/payouts';
import { QuoteCrmIndex } from 'pages/internal-crm/quote';
import { QuoteCrmCreateIndex } from 'pages/internal-crm/quote/create';
import { QuoteCrmDetailsIndex } from 'pages/internal-crm/quote/details';
import { DocumentCrmIndex } from 'pages/internal-crm/resource';
import { RevenueCrmIndex } from 'pages/internal-crm/revenue';
import { ServiceCrmIndex } from 'pages/internal-crm/service';
import { ProductCrmIndex } from 'pages/internal-crm/service/product';
import { SettlementsCrmIndex } from 'pages/internal-crm/settlements';
import { InvoiceEditIndex } from 'pages/invoice/edit';
import { NotificationPortalIndex } from 'pages/notification-portal';
import { NotificationPortalCreateIndex } from 'pages/notification-portal/create';
import NotificationPortalDetail from 'pages/notification-portal/detail';
import { PayoutLayout } from 'pages/payouts';
import { PayoutListIndex } from 'pages/payouts/crm/payouts';
import { RevenueIndex } from 'pages/revenue';
import { CreateAppSubscription } from 'pages/sa-subscription/create';
import { BusinessSetting } from 'pages/settings/business-settings';
import { GeneralSetting } from 'pages/settings/general-settings';
import { UsersSetting } from 'pages/settings/user-settings';
import { SubscriptionIndex } from 'pages/subscription';
import { BillingSubscriptionIndex } from 'pages/subscription/billing';
import { useTranslation } from 'react-i18next';
import { Route } from 'react-router-dom';

import DisputeDetail from './components/disputes/disputes-detail';
import { ROOT_TENANT } from './constants/index.constant';
import { ContactDetailPage } from './pages/contact/detail';
import { DashboardIndex } from './pages/dashboard';
import { DisputesIndex } from './pages/disputes';
import {
    BillingAppSubscriptionCrmIndex,
    CreateAppSubscriptionCrm,
    HelpdeskCrmCreate,
    HelpdeskCrmDetail,
    HelpdeskCrmIndex,
    SaSubscriptionCrmIndex,
} from './pages/internal-crm';
import { SubscriptionCrmIndex } from './pages/internal-crm/subscription';
import { BillingSubscriptionCrmIndex } from './pages/internal-crm/subscription/billing';
import { InvoiceIndex } from './pages/invoice';
import { InvoiceCreateIndex } from './pages/invoice/create';
import { InvoiceDetailsIndex } from './pages/invoice/details';
import { LoginOnBehalf } from './pages/login-on-behalf';
import { MarketplaceIndex } from './pages/markerplace';
import { MarketplaceDetailIndex } from './pages/markerplace/detail';
import { MarketplaceEditIndex } from './pages/markerplace/edit';
import { NotificationIndex } from './pages/notification';
import { NuviooHelpdeskIndex } from './pages/nuvioo-helpdesk';
import { NuviooHelpdeskCreate } from './pages/nuvioo-helpdesk/create';
import { NuviooHelpdeskDetail } from './pages/nuvioo-helpdesk/helpdesk-detail';
import { StepOneIndex } from './pages/onboarding/step-one';
import { StepThreeIndex } from './pages/onboarding/step-three';
import { StepTwoIndex } from './pages/onboarding/step-two';
import { OrderIndex } from './pages/order';
import { OrderCreateIndex } from './pages/order/create';
import { OrderDetailsIndex } from './pages/order/details';
import { PartnerIndex } from './pages/partner';
import { FinixCallbackPage } from './pages/payouts/finix/callback-page';
import { QuoteIndex } from './pages/quote';
import { QuoteCreateIndex } from './pages/quote/create';
import { QuoteDetailsIndex } from './pages/quote/details';
import { DocumentIndex } from './pages/resource';
import { SaSubscriptionIndex } from './pages/sa-subscription';
import { BillingAppSubscriptionIndex } from './pages/sa-subscription/billing';
import { ServiceIndex } from './pages/service';
import { ProductIndex } from './pages/service/product';
import { PaymentMethodSetting } from './pages/settings/payment-method-settings';
import { ProfileSetting } from './pages/settings/profile';
import { SettlementsIndex } from './pages/settlements';
import { TenantIndex } from './pages/tenant';

function isRootTenant(): boolean {
    const auth = JSON.parse(localStorage.getItem('auth') || '{}') || {};
    const { token } = useGetQueryValue<string>(['token']);
    const tenantId = token ? jwt_decode<NPayloadInvoice.IInvoice>(token).tenantId : auth?.tenantId;

    if (tenantId !== ROOT_TENANT) {
        return false;
    }
    return true;
}

function App() {
    const { t: translate } = useTranslation(['layout']);

    const isRoot = isRootTenant();

    const resources = [
        {
            name: 'dashboard',
            list: '/dashboard',
            options: {
                name: translate('sider.dashboard', 'Dashboard'),
            },
            action: 'list',
        },
        {
            name: 'notification',
            list: '/notifications',
            options: {
                name: translate('sider.notification', 'Notification'),
            },
            action: 'list',
        },
        {
            name: 'report_subscription',
            parentName: 'reports',
            list: '/reports/subscription',
            show: '/reports/subscription/show/:id',
            create: '/reports/subscription/create',
            options: {
                name: translate('sider.subscription', 'Subscription'),
            },
            action: 'list',
        },
        {
            name: 'tenant',
            list: 'tenants',
            options: {
                name: translate('sider.tenants', 'Tenants'),
            },
            action: 'list',
        },
        {
            name: 'marketplace',
            list: '/marketplace',
            show: '/marketplace/show/:id',
            edit: '/marketplace/edit/:id',
            options: {
                name: translate('sider.marketplace', 'Marketplace'),
            },
            action: 'list',
        },

        {
            name: 'wl_partner',
            parentName: 'wl',
            list: '/wl/partners',
            options: {
                name: translate('sider.partners', 'Partners'),
            },
            action: 'list',
        },
        {
            name: 'wl_revenue',
            parentName: 'wl',
            list: '/wl/reports/revenue',
            options: {
                name: translate('sider.revenue', 'Revenue'),
            },
            action: 'list',
        },
        {
            name: 'wl_revenue',
            parentName: 'wl',
            list: '/wl/reports/settlements',
            options: {
                name: translate('sider.settlements', 'Settlements'),
            },
            action: 'list',
        },
        {
            name: 'wl_revenue',
            parentName: 'wl',
            list: 'wl/reports/disputes',
            options: {
                name: translate('sider.disputes', 'Disputes'),
            },
            action: 'list',
        },

        {
            name: 'wl_revenue',
            list: `wl/reports/disputes/show/:id`,
            options: {
                name: translate('sider.disputes', 'Disputes'),
            },
            action: 'list',
        },
        {
            name: 'wl_revenue',
            parentName: 'wl',
            list: '/wl/reports/payouts',
            options: {
                name: translate('sider.payouts', 'Payouts'),
            },
            action: 'list',
        },
        {
            name: 'wl_service',
            parentName: 'wl',
            list: '/wl/services',
            show: '/wl/services/show/:id',
            options: {
                name: translate('sider.services', 'Services'),
            },
            action: 'list',
        },
        {
            name: `wl_contact`,
            parentName: 'wl',
            list: '/wl/contacts',
            show: '/wl/contacts/show/:id',
            options: {
                name: translate('sider.contacts', 'Contacts'),
            },
            action: 'list',
        },

        {
            name: 'wl_quote',
            parentName: 'wl',
            list: '/wl/quotes',
            create: '/wl/quotes/create',
            edit: '/wl/quotes/edit/:id',
            show: '/wl/quotes/show/:id',
            options: {
                name: translate('sider.quotes', 'Quotes'),
            },
            action: 'list',
        },
        {
            name: 'wl_order',
            parentName: 'wl',
            list: '/wl/orders',
            create: '/wl/orders/create',
            show: '/wl/orders/show/:id',
            options: {
                name: translate('sider.orders', 'Orders'),
            },
            action: 'list',
        },
        {
            name: 'wl_invoice',
            parentName: 'wl',
            list: '/wl/invoices',
            create: '/wl/invoices/create',
            edit: '/wl/invoices/edit/:id',
            show: '/wl/invoices/show/:id',
            options: {
                name: translate('sider.invoices', 'Invoices'),
            },
            action: 'list',
        },
        {
            name: 'wl_helpdesk',
            parentName: 'wl',
            list: '/wl/helpdesk',
            create: '/wl/helpdesk/create',
            show: '/wl/helpdesk/show/:id',
            options: {
                name: translate('sider.helpdesk', 'Help Desk'),
            },
            action: 'list',
        },
        {
            name: 'wl_resource',
            parentName: 'wl',
            list: '/wl/resource',
            options: {
                name: translate('sider.resource', 'Resources'),
            },
            action: 'list',
        },
        {
            name: 'profile_setting',
            list: '/setting/profile',
            options: {
                name: translate('sider.profile', 'Profile'),
            },
            action: 'list',
        },
        {
            name: 'wl_payouts',
            parentName: 'wl',
            list: '/wl/payouts',
            options: {
                name: translate('sider.payouts', 'Payouts'),
            },
            action: 'list',
        },
        {
            name: 'wl_payouts',
            parentName: 'wl',
            list: '/wl/payment-methods',
            options: {
                name: translate('sider.general_billing', 'Billing Setting'),
            },
            action: 'list',
        },
        {
            name: `wl_payouts`,
            parentName: 'wl',
            list: `wl/callback/nuvioo`,
            action: 'list',
        },
        {
            name: 'wl_setting',
            parentName: 'wl',
            list: '/wl/payout',
            options: {
                name: translate('sider.payout_settings', 'Payout Settings'),
            },
            action: 'list',
        },
        {
            name: 'wl_recurringInvoice',
            parentName: 'wl',
            list: '/wl/recurring-invoices',
            show: '/wl/recurring-invoices/show/:id',
            options: {
                name: translate('sider.recurring_invoices', 'Recurring Invoices'),
            },
            action: 'list',
        },
        {
            name: 'users',
            parentName: 'setting',
            list: '/setting/users',
            options: {
                name: translate('sider.users', 'Users'),
            },
            action: 'list',
        },
        {
            name: 'business',
            parentName: 'setting',
            list: '/setting/business',
            options: {
                name: translate('sider.business_branding', 'Business Branding'),
            },
            action: 'list',
        },
        {
            name: 'general',
            parentName: 'setting',
            list: '/setting/general',
            options: {
                name: translate('sider.general_setting', 'General Setting'),
            },
            action: 'list',
        },
        {
            name: 'billing',
            parentName: 'setting',
            list: '/setting/billing',
            options: {
                name: translate('sider.general_billing', 'Billing Setting'),
            },
        },
        {
            name: 'tenant_helpdesk',
            parentName: 'tenant',
            list: '/tenant/helpdesk',
            create: '/tenant/helpdesk/create',
            show: '/tenant/helpdesk/show/:id',
            options: {
                name: translate('sider.helpdesk', 'Help Desk'),
            },
            action: 'list',
        },
        {
            name: `notification_portal`,
            parentName: 'tenant',
            list: '/tenant/notification-portal',
            create: '/tenant/notification-portal/create',
            show: '/tenant/notification-portal/show/:id',
            options: {
                name: translate('sider.notification', 'Notification'),
            },
            action: 'list',
        },
    ];

    const internalCrmResource = [
        {
            name: 'wl_revenue',
            parentName: 'wl',
            list: '/sale/reports/revenue',
            options: {
                name: translate('sider.revenue', 'Revenue'),
            },
            action: 'list',
        },
        {
            name: 'wl_revenue',
            parentName: 'wl',
            list: '/sale/reports/settlements',
            options: {
                name: translate('sider.settlements', 'Settlements'),
            },
            action: 'list',
        },
        {
            name: 'wl_revenue',
            parentName: 'wl',
            list: '/sale/reports/payouts',
            options: {
                name: translate('sider.payouts', 'Payouts'),
            },
            action: 'list',
        },
        {
            name: 'wl_service',
            parentName: 'wl',
            list: '/sale/services',
            show: '/sale/services/show/:id',
            options: {
                name: translate('sider.services', 'Services'),
            },
            action: 'list',
        },
        {
            name: 'wl_partner',
            parentName: 'wl',
            list: '/sale/partners',
            options: {
                name: translate('sider.resellers', 'Resellers'),
            },
            action: 'list',
        },

        {
            name: 'wl_setting',
            parentName: 'wl',
            list: '/sale/payout',
            options: {
                name: translate('sider.payout_settings', 'Payout Settings'),
            },
            action: 'list',
        },
        {
            name: `wl_contact`,
            parentName: 'wl',
            list: '/sale/contacts',
            show: '/sale/contacts/show/:id',
            options: {
                name: translate('sider.contacts', 'Contacts'),
            },
            action: 'list',
        },
        {
            name: 'wl_quote',
            parentName: 'wl',
            list: '/sale/quotes',
            create: '/sale/quotes/create',
            edit: '/sale/quotes/edit/:id',
            show: '/sale/quotes/show/:id',
            options: {
                name: translate('sider.quotes', 'Quotes'),
            },
            action: 'list',
        },
        {
            name: 'wl_invoice',
            parentName: 'wl',
            list: '/sale/invoices',
            create: '/sale/invoices/create',
            edit: '/sale/invoices/edit/:id',
            show: '/sale/invoices/show/:id',
            options: {
                name: translate('sider.invoices', 'Invoices'),
            },
            action: 'list',
            meta: { dataProviderName: 'internalCrm' },
        },
        {
            name: 'wl_resource',
            parentName: 'wl',
            list: '/sale/resource',
            options: {
                name: translate('sider.resource', 'Resources'),
            },
            action: 'list',
        },
        {
            name: 'wl_helpdesk',
            parentName: 'wl',
            list: '/sale/helpdesk',
            create: '/sale/helpdesk/create',
            show: '/sale/helpdesk/show/:id',
            options: {
                name: translate('sider.helpdesk', 'Help Desk'),
            },
            action: 'list',
        },
    ];

    return (
        <Nuvioo
            dataProvider={{
                default: dataProvider.default(getEnvConfig.API_URL),
                notification: dataProvider.default(getEnvConfig.NOTIFICATION_URL),
                internalCrm: dataProvider.default(getEnvConfig.INTERNAL_CRM_API_URL),
            }}
            initialRoute={'/dashboard'}
            resources={[...resources, ...internalCrmResource]}
            nonAuthRoute={
                <>
                    <Route path="/onboarding/step-one" element={<StepOneIndex />} />
                    <Route path="/onboarding/step-two" element={<StepTwoIndex />} />
                    <Route path="/onboarding/step-three" element={<StepThreeIndex />} />
                    <Route
                        path="/quotes/public/:id"
                        element={isRoot ? <QuoteCrmDetailsIndex /> : <QuoteDetailsIndex />}
                    />
                    <Route
                        path="/invoices/public/:id"
                        element={isRoot ? <InvoiceDetailsCrmIndex /> : <InvoiceDetailsIndex />}
                    />
                    <Route path="/login-on-behalf" element={<LoginOnBehalf />} />
                    <Route path="/register" element={<ExtraApplicationPlanIndex />} />
                    <Route path={`wl/callback/nuvioo`} element={<FinixCallbackPage />}></Route>
                </>
            }
        >
            <Route path="/dashboard" element={<DashboardIndex />} />
            <Route path="/notifications" element={<NotificationIndex />} />
            <Route path="/marketplace">
                <Route index element={<MarketplaceIndex />} />
                <Route path="edit/:id" element={<MarketplaceEditIndex />} />
                <Route path="show/:id" element={<MarketplaceDetailIndex />} />
            </Route>
            <Route path="/tenant/helpdesk">
                <Route index element={<NuviooHelpdeskIndex />} />
                <Route path="create" element={<NuviooHelpdeskCreate />} />
                <Route path="show/:id" element={<NuviooHelpdeskDetail />} />
            </Route>
            <Route path="/tenant/notification-portal">
                <Route index element={<NotificationPortalIndex />} />
                <Route path="create" element={<NotificationPortalCreateIndex />} />
                <Route path="show/:id" element={<NotificationPortalDetail />} />
            </Route>
            <Route path="/setting">
                <Route path="business" element={<BusinessSetting />} />
                <Route path="general" element={<GeneralSetting />} />
                <Route path="users" element={<UsersSetting />} />
                <Route path="billing" element={<PaymentMethodSetting />} />
                <Route path="profile" element={<ProfileSetting />} />
            </Route>
            <Route path="/reports">
                <Route path="subscription" element={<SaSubscriptionIndex />} />
                <Route path="subscription/show/:id" element={<BillingAppSubscriptionIndex />} />

                <Route path="subscription/create" element={<CreateAppSubscription />} />
            </Route>
            <Route path="/tenants">
                <Route index element={<TenantIndex />} />
            </Route>
            {!isRoot ? (
                <Route path="/wl">
                    <Route path="reports/revenue" element={<RevenueIndex />} />
                    <Route path="reports/payouts" element={<PayoutListIndex />} />
                    <Route path="reports/settlements" element={<SettlementsIndex />} />
                    <Route path="reports/disputes">
                        <Route index element={<DisputesIndex />} />
                        <Route path="show/:id" element={<DisputeDetail />} />
                    </Route>
                    <Route path="services">
                        <Route index element={<ServiceIndex />} />
                        <Route path="show/:id" element={<ProductIndex />} />
                    </Route>
                    <Route path="partners">
                        <Route index element={<PartnerIndex />} />
                    </Route>
                    <Route path="partners" element={<PartnerIndex />} />
                    <Route path="payout" element={<PayoutLayout />} />
                    <Route path="payment-methods" element={<PaymentMethodSetting />} />
                    <Route path="contacts">
                        <Route index element={<ContactIndex />} />
                        <Route path="show/:id" element={<ContactDetailPage />} />
                    </Route>
                    <Route path="quotes">
                        <Route index element={<QuoteIndex />} />
                        <Route path="create" element={<QuoteCreateIndex />} />
                        <Route path="edit/:id" element={<QuoteCreateIndex />} />
                        <Route path="show/:id" element={<QuoteDetailsIndex />} />
                    </Route>
                    <Route path="orders">
                        <Route index element={<OrderIndex />} />
                        <Route path="create" element={<OrderCreateIndex />} />
                        <Route path="show/:id" element={<OrderDetailsIndex />} />
                    </Route>
                    <Route path="invoices">
                        <Route index element={<InvoiceIndex />} />
                        <Route path="create" element={<InvoiceCreateIndex />} />
                        <Route path="edit/:id" element={<InvoiceEditIndex />} />
                        <Route path="show/:id" element={<InvoiceDetailsIndex />} />
                    </Route>
                    <Route path="recurring-invoices">
                        <Route index element={<SubscriptionIndex />} />
                        <Route path="show/:id" element={<BillingSubscriptionIndex />} />
                    </Route>
                    <Route path="resource">
                        <Route index element={<DocumentIndex />} />
                    </Route>
                    <Route path="helpdesk">
                        <Route index element={<HelpdeskIndex />} />
                        <Route path="create" element={<HelpdeskCreate />} />
                        <Route path="show/:id" element={<HelpdeskDetail />} />
                    </Route>
                </Route>
            ) : (
                <Route path="/sale">
                    <Route path="reports">
                        <Route path="revenue" element={<RevenueCrmIndex />} />
                        <Route path="settlements" element={<SettlementsCrmIndex />} />
                        <Route path="payouts" element={<PayoutReportCrmIndex />} />
                        <Route path="subscription" element={<SaSubscriptionCrmIndex />} />
                        <Route
                            path="subscription/show/:id"
                            element={<BillingAppSubscriptionCrmIndex />}
                        />
                        <Route path="subscription/create" element={<CreateAppSubscriptionCrm />} />
                    </Route>

                    <Route path="services">
                        <Route index element={<ServiceCrmIndex />} />
                        <Route path="show/:id" element={<ProductCrmIndex />} />
                    </Route>
                    <Route path="partners">
                        <Route index element={<PartnerCrmIndex />} />
                    </Route>
                    <Route path="payout" element={<PayoutCrmLayoutPage />} />
                    <Route path="payouts" element={<PayoutListIndex />} />
                    {/* <Route path="payment-methods" element={<BillingSetting />} /> */}
                    <Route path="contacts">
                        <Route index element={<ContactCrmIndex />} />
                        <Route path="show/:id" element={<ContactDetailCrmPage />} />
                    </Route>
                    <Route path="quotes">
                        <Route index element={<QuoteCrmIndex />} />
                        <Route path="create" element={<QuoteCrmCreateIndex />} />
                        <Route path="edit/:id" element={<QuoteCrmCreateIndex />} />
                        <Route path="show/:id" element={<QuoteCrmDetailsIndex />} />
                    </Route>

                    <Route path="invoices">
                        <Route index element={<InvoiceCrmIndex />} />
                        <Route path="create" element={<InvoiceCreateCrmIndex />} />
                        <Route path="edit/:id" element={<InvoiceEditCrmIndex />} />
                        <Route path="show/:id" element={<InvoiceDetailsCrmIndex />} />
                    </Route>
                    <Route path="recurring-invoices">
                        <Route index element={<SubscriptionCrmIndex />} />
                        <Route path="show/:id" element={<BillingSubscriptionCrmIndex />} />
                    </Route>
                    <Route path="resource">
                        <Route index element={<DocumentCrmIndex />} />
                    </Route>
                    <Route path="helpdesk">
                        <Route index element={<HelpdeskCrmIndex />} />
                        <Route path="create" element={<HelpdeskCrmCreate />} />
                        <Route path="show/:id" element={<HelpdeskCrmDetail />} />
                    </Route>
                </Route>
            )}
        </Nuvioo>
    );
}

export default App;
