import { PERMISSIONS } from '@constants/permission';
import { WLSetting } from '@interfaces/white-label-setting';
import { useApiUrl, useCustomMutation, usePermissions, useUpdate } from '@refinedev/core';
import { Button, Col, Form, Input, InputNumber, Row, Spin, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OnboardingForm } from './onboarding-form';
import styles from './styles.module.scss';

enum FormFields {
    WL_PARTNER_DISCOUNT_PERCENTAGE = 'wl_partner_discount_percentage',
    MAXIMUM_MARGIN_PERCENTAGE = 'maximum_margin_percentage',
    SERVICE_CHARGE_PERCENTAGE = 'service_charge_percentage',
}
interface FinixProps {
    wlPayout?: WLSetting.IWLPayoutSetting;
    onSubmit: (request: {
        merchantId: string;
        data?: {
            [name: string]: string;
        };
    }) => void;
    isSuccessOnboarding?: boolean;
}

export const FinixPaymentGateway: React.FC<FinixProps> = ({
    wlPayout,
    onSubmit,
    isSuccessOnboarding,
}) => {
    const { t } = useTranslation('common');
    const [form] = Form.useForm();
    const { data: permissionsData } = usePermissions<string[]>();
    const { mutate: createLink, isLoading } = useCustomMutation<any>();
    const { mutate: update } = useUpdate();

    const [manualInput, setManualInput] = useState(false);
    const [onboardingUrl, setOnboardingUrl] = useState<string>();
    const apiUrl = useApiUrl();

    useEffect(() => {
        if (wlPayout) {
            form.setFieldsValue({
                merchantId: wlPayout.merchantId,
                ...wlPayout.data,
            });
        }
        setManualInput(false);
    }, [wlPayout]);

    const submitOnboarding = async () => {
        let _tData: any = {
            url: `${apiUrl}/v1/payments/finix/onboarding`,
            method: 'post',
            values: {},
            successNotification: {
                message: t('create_success'),
                type: 'success',
            },
            errorNotification: {
                message: t('join_failed'),
                type: 'error',
            },
        };
        if (wlPayout && !wlPayout?.merchantId) {
            _tData = {
                url: `${apiUrl}/v1/payments/finix/update-onboarding`,
                method: 'put',
                values: {},
                successNotification: {
                    message: t('update_success'),
                    type: 'success',
                },
                errorNotification: {
                    message: t('join_failed'),
                    type: 'error',
                },
            };
        }

        createLink(
            {
                ..._tData,
            },
            {
                onSuccess: (response) => {
                    if (response && response?.data) {
                        setOnboardingUrl(response.data.data);
                    }
                },
            },
        );
    };

    useEffect(() => {
        (window as any).finixCallback = (identityId: string) => {
            console.log('identityId', identityId);
            update(
                {
                    resource: `v1/payments/finix/update-merchant`,
                    id: '' as string,
                    values: {
                        identityId,
                    },
                    errorNotification: false,
                    successNotification: false,
                },
                {
                    onSettled: () => {
                        window.location.href = getFinixRedirectUrl();
                    },
                },
            );
        };
    }, []);

    // Submit the form with validated input values
    const submitForm = async () => {
        try {
            const values = await form.validateFields();
            const request = {
                merchantId: values.merchantId,
                data: {},
            };

            if (wlPayout?.merchantId) {
                request.data = {
                    [FormFields.WL_PARTNER_DISCOUNT_PERCENTAGE]:
                        values[FormFields.WL_PARTNER_DISCOUNT_PERCENTAGE] || 0,
                    [FormFields.MAXIMUM_MARGIN_PERCENTAGE]:
                        values[FormFields.MAXIMUM_MARGIN_PERCENTAGE] || 0,
                    [FormFields.SERVICE_CHARGE_PERCENTAGE]:
                        values[FormFields.SERVICE_CHARGE_PERCENTAGE] || 0,
                };
            }
            onSubmit(request);
        } catch (error) {
            console.error('Form validation failed:', error);
        }
    };
    const getFinixRedirectUrl = () => {
        if (window) {
            const { origin, pathname } = window.location;
            return `${origin}${pathname}`;
        }
        return '';
    };

    return onboardingUrl ? (
        <OnboardingForm url={onboardingUrl} />
    ) : (
        <Spin spinning={isLoading}>
            <section className="finix-terminal-payment">
                {!manualInput &&
                    !wlPayout?.merchantId &&
                    (permissionsData || []).includes(PERMISSIONS.WL_PAYOUTS_EDIT) && (
                        <>
                            <Tooltip
                                title={
                                    isSuccessOnboarding
                                        ? t('payouts.is_success_onboarding')
                                        : undefined
                                }
                                trigger="hover"
                            >
                                <Button
                                    className={`btn-submit ${
                                        isSuccessOnboarding ? '' : 'animated fadeInDown'
                                    }`}
                                    onClick={submitOnboarding}
                                    disabled={!!isSuccessOnboarding}
                                >
                                    {t(
                                        wlPayout &&
                                            wlPayout?.configuration?.status === 'IN_PROGRESS'
                                            ? 'payouts.continue'
                                            : 'payouts.join_payment',
                                    )}
                                </Button>
                            </Tooltip>
                            <span className="ml-3 mr-3">OR</span>
                            <Button
                                className="btn-submit animated fadeInDown"
                                onClick={() => setManualInput(true)}
                            >
                                {t('payouts.input_configuration')}
                            </Button>
                        </>
                    )}

                {(manualInput || wlPayout?.merchantId) && (
                    <>
                        <div className="flex items-start justify-between">
                            <h3 className="text-base font-bold mb-4">
                                {t('payouts.manual_setting_payment')}
                            </h3>
                            {(permissionsData || []).includes(PERMISSIONS.WL_PAYOUTS_EDIT) && (
                                <Button
                                    className="btn-submit animated fadeInDown"
                                    onClick={submitForm}
                                >
                                    {t('buttons.save', { ns: 'common' })}
                                </Button>
                            )}
                        </div>
                        <Form form={form} layout="vertical" className={styles.formManualInput}>
                            <Form.Item
                                name="merchantId"
                                label={t('payouts.merchant_id')}
                                rules={[
                                    { required: true, message: t('payouts.merchant_id_required') },
                                ]}
                            >
                                <Input placeholder={t('payouts.merchant_id_placeholder')} />
                            </Form.Item>
                            {wlPayout?.merchantId &&
                                (permissionsData || []).includes(PERMISSIONS.WL_SETTING_CREATE) && (
                                    <div className="wl-setting-container">
                                        <h3 className="text-base font-bold mb-4">
                                            {t('wl_setting.white_label_setting')}
                                        </h3>
                                        <Row gutter={16}>
                                            {Object.values(FormFields).map((field, index) => (
                                                <Col
                                                    key={index}
                                                    span={8}
                                                    className="form-item-payout-setting"
                                                >
                                                    <Form.Item
                                                        name={field}
                                                        label={t(`wl_setting.${field}`)}
                                                        rules={[
                                                            {
                                                                required: !!wlPayout?.merchantId,
                                                                message: t(
                                                                    'wl_setting.value_required',
                                                                ),
                                                            },
                                                        ]}
                                                    >
                                                        <InputNumber
                                                            min={0}
                                                            max={100}
                                                            className="w-3/4"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                )}
                        </Form>
                    </>
                )}
            </section>
        </Spin>
    );
};
