import { DownloadPrint } from '@components/contact/download-pdf';
import { ExportTypeEnum } from '@enums/export-file.enum';
import { Space } from 'antd';
import { useFilterContext } from 'contexts/filter.context';
import moment from 'moment-timezone';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SettlementsInformation } from '../settlements-information';
import { DateRangeFilter } from './date-range-filter';

const Settlements = () => {
    const { t } = useTranslation(['dashboard']);

    const timeZone = moment.tz.guess();

    const { filter, onSetDateRange } = useFilterContext();

    const [isValidDay, setIsValidDay] = useState<boolean>(true);

    return (
        <section className="filter py-4 order-report-container">
            <div className="flex justify-between filter-wrapper-container">
                <Space size={16} direction="horizontal" wrap={true} className="mb-8">
                    <DateRangeFilter
                        dateRange={filter?.dateRange}
                        setDateRange={onSetDateRange}
                        reportType={ExportTypeEnum.SETTLEMENTS}
                    />
                </Space>

                <DownloadPrint
                    title={t('settlements.title', { ns: 'dashboard' })}
                    dateRange={filter?.dateRange}
                    reportType={ExportTypeEnum.SETTLEMENTS}
                    timeZone={timeZone}
                    isExportExcel
                />
            </div>

            <SettlementsInformation
                filter={{ dateRange: filter?.dateRange }}
                timeZone={timeZone}
                isValidDay={isValidDay}
            />
        </section>
    );
};

export default Settlements;
