import './styles.scss';

import { CustomPagination } from '@components/modules/pagination';
import { Payout } from '@interfaces/payouts';
import { BaseRecord, useTable, useTranslate } from '@refinedev/core';
import { Col, Row, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React from 'react';
import { convertPrice } from 'utils/resource';

interface IProps {
    name?: string;
}

type PayoutData = {
    payouts: Payout.IPayout[];
    totalPayout: number;
} & BaseRecord[];

export const PAYOUT_RESOURCE_API = 'v1/payouts';

export const PayoutListIndex: React.FC<IProps> = () => {
    const translate = useTranslate();

    const {
        tableQueryResult: payoutData,
        current,
        setCurrent,
        pageSize,
        setPageSize,
    } = useTable({
        resource: PAYOUT_RESOURCE_API,
    });

    const columns: ColumnsType<Payout.IPayout> = [
        {
            title: translate('payouts.invoice_id'),
            dataIndex: 'invoiceIncrementId',
            render: (_, item) => {
                return <div className="table-tbody-text">{item?.invoiceIncrementId}</div>;
            },
        },
        {
            title: translate('payouts.product_name'),
            dataIndex: 'productName',
            render: (_, item) => {
                return <div className="table-tbody-text">{item?.productName}</div>;
            },
        },
        {
            title: translate('payouts.amount'),
            dataIndex: 'amount',
            render: (_, item) => {
                return <div className="table-tbody-text">{convertPrice(item?.amount)}</div>;
            },
        },
        {
            title: translate('payouts.status'),
            dataIndex: 'status',
            render: (_, item) => {
                return (
                    <div className="table-tbody-text">
                        {translate('payouts.status_enum.' + item?.status)}
                    </div>
                );
            },
        },
    ];

    const _payoutData = payoutData?.data?.data as PayoutData;
    return (
        <>
            <section className="payouts-container w-full h-full">
                <>
                    {_payoutData?.payouts?.length ? (
                        <Row className="payouts-row mb-6 items-center">
                            <Col xs={24} sm={12} className="flex justify-start">
                                <div className="total-invoices-amount flex text-lg font-bold">
                                    <span className="mr-2">
                                        {translate('payouts.total_payout_amount')}:{' '}
                                    </span>
                                    <span>{convertPrice(_payoutData?.totalPayout ?? 0)}</span>
                                </div>
                            </Col>
                        </Row>
                    ) : null}
                    <Row className="payouts-table-container">
                        <Col span={24} className="flex flex-col">
                            <Table
                                className="payouts-table"
                                columns={columns}
                                dataSource={
                                    _payoutData?.payouts.filter((item) => item.amount > 0) || []
                                }
                                loading={payoutData?.isLoading}
                                pagination={false}
                                scroll={{ x: '700px', y: '100%' }}
                            />
                        </Col>
                        <Col span={24}>
                            <div className="pagination-container pt-3 pb-4 px-6">
                                <CustomPagination
                                    pageSize={pageSize}
                                    total={payoutData?.data?.total}
                                    current={current}
                                    onChange={(value: any, size: any) => {
                                        setCurrent(value);
                                        setPageSize(size);
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </>
            </section>
        </>
    );
};
