import { QuoteStatus } from '@enums/quote-status.enum';
import { useCustom } from '@refinedev/core';
import { FONT_INTER } from '@utils/fonts';
import { convertPrice, formatDate, formatPrice } from '@utils/resource';
import { maskPhone } from '@utils/string';
import { Button, Col, Row, Tag } from 'antd';
import { getEnvConfig } from 'getEnvConfig';
import jsPDF from 'jspdf';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

interface IBillingModal {
    billingId: string;
    onCancel?: () => void;
}
const BillingCrmDetail = ({ billingId, onCancel }: IBillingModal) => {
    const apiUrl = getEnvConfig.INTERNAL_CRM_API_URL;
    const { t: translate } = useTranslation(['common', 'quote']);

    const componentToPrint = useRef<any>();
    const {
        data: billingDetail,
        refetch: billingRefetch,
        isLoading,
        error,
    } = useCustom<any>({
        url: `${apiUrl}/v1/invoices/billing-details/${billingId}`,
        method: 'get',
    });

    const handleDownload = useReactToPrint({
        content: () => componentToPrint.current,
        copyStyles: true,
        pageStyle: `
        @media all {
            .no-print {
                display: none!important;
            }
            .no-space {
                letter-spacing: 0.01px;
            }
        }
        `,
        print: async (printIframe: any) => {
            const document = printIframe.contentDocument;
            if (document) {
                const html = document.getElementsByTagName('html')[0];
                const customPrintPdf = html.querySelector('.custom-print-pdf');
                customPrintPdf.style.marginTop = '15px';
                customPrintPdf.style.paddingBottom = '12px';
                html.style.width = '900px';

                const doc = new jsPDF('p', 'px');
                doc.addFileToVFS('inter.ttf', FONT_INTER);
                doc.addFont('inter.ttf', 'inter', 'normal');
                doc.setFont('inter', 'normal');
                doc.html(html, {
                    html2canvas: {
                        scale: 0.5,
                    },
                    async callback(doc) {
                        await doc.save(
                            `${translate('subscriptions.billing_of_invoice')} #${
                                billingDetail?.data.incrementId
                            }`,
                        );
                    },
                });
            }
        },
    });

    const mappingColorInvoiceStatus: { [key in QuoteStatus]?: string } = {
        [QuoteStatus.Approved]: 'green',
        [QuoteStatus.Paid]: 'green',
        [QuoteStatus.Pending]: 'blue',
        [QuoteStatus.Rejected]: 'red',
    };

    return (
        <>
            {!isLoading && !error && (
                <section
                    className="invoice-container invoice-detail"
                    ref={(el) => (componentToPrint.current = el)}
                >
                    <div className="block-heading">
                        <div className="date-wrapper">
                            <div className="flex justify-start items-center">
                                <span>
                                    {translate('quotes.heading.invoiceDetails', { ns: 'quote' })}:{' '}
                                    {billingDetail.data?.incrementId}
                                </span>

                                {billingDetail?.data && billingDetail?.data?.status ? (
                                    <Tag
                                        className="ml-4 custom-print-pdf"
                                        color={
                                            mappingColorInvoiceStatus[
                                                billingDetail.data.status as QuoteStatus
                                            ] ?? 'default'
                                        }
                                    >
                                        {translate('quotes.status.' + billingDetail?.data?.status, {
                                            ns: 'quote',
                                        })}
                                    </Tag>
                                ) : null}
                            </div>
                            <div className="date-container">
                                <span className="date-title">
                                    {translate('quotes.fields.paymentDate.label', { ns: 'quote' })}{' '}
                                    - {formatDate(billingDetail?.data?.date, 'MMM DD, YYYY')}
                                </span>
                            </div>
                        </div>
                        <div className="ml-auto">
                            <Button
                                type="link"
                                icon={<img src="/images/icons/download.svg" />}
                                className="no-print mr-3"
                                onClick={handleDownload}
                            />
                        </div>
                    </div>
                    {billingDetail?.data?.reason ? (
                        <div className="details-section">
                            <div className="section-header">
                                {translate('invoice.change_status_reason', { ns: 'quote' })}
                            </div>
                            <Row gutter={20}>
                                <Col md={24}>
                                    <pre>{billingDetail?.data?.reason}</pre>
                                </Col>
                            </Row>
                        </div>
                    ) : null}
                    {billingDetail?.data?.description && (
                        <div className="details-section">
                            <div className="section-header">
                                {translate('quotes.fields.description.label', { ns: 'quote' })}
                            </div>
                            <Row gutter={20}>
                                <Col md={24}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: billingDetail?.data?.description as string,
                                        }}
                                    ></div>
                                </Col>
                            </Row>
                        </div>
                    )}
                    <div className="details-section">
                        <Row gutter={20}>
                            <Col md={12} className="user-info-col">
                                <div className="user-info">
                                    <div className="info-header">
                                        {translate('quotes.heading.createdBy', { ns: 'quote' })}
                                    </div>
                                    <div className="name">
                                        {(billingDetail?.data?.createdByUser?.firstName ?? '') +
                                            ' ' +
                                            (billingDetail?.data?.createdByUser?.lastName ?? '')}
                                    </div>
                                    <div className="no-space">
                                        {billingDetail?.data?.createdByUser?.email ?? ''}
                                    </div>
                                    <div className="no-space">
                                        {billingDetail?.data?.createdByUser?.phone
                                            ? maskPhone(billingDetail?.data?.createdByUser?.phone)
                                            : ''}
                                    </div>
                                </div>
                            </Col>
                            <Col md={12} className="user-info-col">
                                <div className="user-info text-right">
                                    <div className="info-header">
                                        {translate('quotes.heading.for', { ns: 'quote' })}
                                    </div>
                                    <div className="name">{billingDetail?.data?.contactName}</div>
                                    <div className="no-space">
                                        {billingDetail?.data?.contactEmail}
                                    </div>
                                    <div className="no-space">
                                        {billingDetail?.data?.contactPhone
                                            ? maskPhone(billingDetail?.data?.contactPhone)
                                            : ''}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="details-section invoice-items-table">
                        <div className="section-header">
                            {translate('quotes.heading.itemDetails', { ns: 'quote' })}
                        </div>

                        <Row className="item-list">
                            <Col span={24}>
                                <Row className="table-header">
                                    <Col span={6}>
                                        {translate('quotes.fields.item.label', { ns: 'quote' })}
                                    </Col>
                                    <Col span={4}>
                                        {translate('quotes.fields.frequency.label', {
                                            ns: 'quote',
                                        })}
                                    </Col>

                                    <Col span={4}>
                                        {translate('quotes.fields.qty.label', {
                                            ns: 'quote',
                                        })}
                                    </Col>
                                    <Col span={4}>
                                        {translate('quotes.fields.unitPrice.label', {
                                            ns: 'quote',
                                        })}
                                    </Col>
                                    <Col span={6}>
                                        {' '}
                                        {translate('quotes.fields.total.label', {
                                            ns: 'quote',
                                        })}
                                    </Col>
                                </Row>
                                <div>
                                    {billingDetail?.data &&
                                        billingDetail?.data?.items?.length > 0 &&
                                        billingDetail?.data?.items?.map((item: any) => {
                                            return (
                                                <Row
                                                    className="list-product-item"
                                                    key={item?.productName + item?.id}
                                                >
                                                    <Col span={6}>{item.productName}</Col>
                                                    <Col span={4}>
                                                        {translate(
                                                            'quotes.frequency.' + item.frequency,
                                                            { ns: 'quote' },
                                                        )}
                                                    </Col>
                                                    <Col span={4}>{item.qty}</Col>
                                                    <Col span={4}>
                                                        {formatPrice(
                                                            item?.unitPrice
                                                                ? item?.unitPrice +
                                                                      (item?.unitPrice *
                                                                          (item?.margin ?? 0)) /
                                                                          100
                                                                : item.total / item.qty,
                                                        )}
                                                    </Col>

                                                    <Col span={6}>
                                                        {convertPrice(
                                                            +Number(billingDetail?.data?.amount),
                                                        )}
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="invoice-items-list">
                        {billingDetail?.data &&
                            billingDetail?.data?.items?.length > 0 &&
                            billingDetail?.data?.items?.map((item: any) => {
                                return (
                                    <div className="p-6" key={item?.productName + item?.id}>
                                        <Row key={`name_${item.id}`} className="list-product-item">
                                            <Col span={12} className="title">
                                                <span>
                                                    {translate('quotes.fields.item.label', {
                                                        ns: 'quote',
                                                    })}
                                                </span>
                                            </Col>
                                            <Col span={12} className="value">
                                                <span>{item.productName}</span>
                                            </Col>
                                        </Row>
                                        <Row key={`title_${item.id}`} className="list-product-item">
                                            <Col span={12} className="title">
                                                <span>
                                                    {translate('quotes.fields.frequency.label', {
                                                        ns: 'quote',
                                                    })}
                                                </span>
                                            </Col>
                                            <Col span={12} className="value">
                                                <span>
                                                    {translate(
                                                        'quotes.frequency.' + item.frequency,
                                                        { ns: 'quote' },
                                                    )}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row key={`qty_${item.id}`} className="list-product-item">
                                            <Col span={12} className="title">
                                                <span>
                                                    {translate('quotes.fields.qty.label', {
                                                        ns: 'quote',
                                                    })}
                                                </span>
                                            </Col>
                                            <Col span={12} className="value">
                                                {item.qty}
                                            </Col>
                                        </Row>
                                        <Row
                                            key={`frequency_${item.id}`}
                                            className="list-product-item"
                                        >
                                            <Col span={12} className="title">
                                                <span>
                                                    {' '}
                                                    {translate('quotes.fields.unitPrice.label', {
                                                        ns: 'quote',
                                                    })}
                                                </span>
                                            </Col>
                                            <Col span={12} className="value">
                                                <span>
                                                    {formatPrice(
                                                        item?.unitPrice
                                                            ? item?.unitPrice +
                                                                  (item?.unitPrice *
                                                                      (item?.margin ?? 0)) /
                                                                      100
                                                            : item.total / item.qty,
                                                    )}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row key={`total_${item.id}`} className="list-product-item">
                                            <Col span={12} className="title">
                                                <span>
                                                    {translate('quotes.fields.total.label', {
                                                        ns: 'quote',
                                                    })}
                                                </span>
                                            </Col>
                                            <Col span={12} className="value">
                                                <span>{formatPrice(item.total)}</span>
                                            </Col>
                                        </Row>
                                    </div>
                                );
                            })}
                    </div>
                </section>
            )}
        </>
    );
};

export default BillingCrmDetail;
