import { ProductFrequencyEnum } from '@enums/frequency.enum';
import { NReport } from '@interfaces/components/report';
import { IInvoice } from '@interfaces/invoice';
import { Col, Row, Table, Tabs, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import TabPane from 'antd/es/tabs/TabPane';
import { formatPriceBaseOnCurrency } from 'common/functions/format-price';
import { useTranslation } from 'react-i18next';

import TooltipWithCopy from '../../tooltip-with-copy';
import styles from '../styles.module.scss';

type IDisputeOrderDetail = {
    title: string;
    orderDetail: NReport.IOrderDetail;
};

const { Text, Title } = Typography;

const DisputeOrderDetail = ({ orderDetail, title }: IDisputeOrderDetail) => {
    const { t } = useTranslation(['common']);

    const orderItems: ColumnsType<IInvoice.InvoiceItem> = [
        {
            title: (
                <div className=" table-thead-text rounded">
                    {t(`disputes.items.product_name`, { ns: 'common' })}
                </div>
            ),
            dataIndex: 'productName',
            key: 'productName',
            render: (productName: string) => (
                <div>
                    <p className="table-tbody-text">{productName}</p>
                </div>
            ),
        },

        {
            title: (
                <div className=" table-thead-text rounded">
                    {t(`disputes.items.qty`, { ns: 'common' })}
                </div>
            ),
            dataIndex: 'qty',
            key: 'qty',
            render: (qty: number) => (
                <div>
                    <p className="table-tbody-text">{qty}</p>
                </div>
            ),
        },
        {
            title: (
                <div className=" table-thead-text rounded">
                    {t(`disputes.items.margin`, { ns: 'common' })}
                </div>
            ),
            dataIndex: 'margin',
            key: 'margin',
            render: (margin: number) => (
                <div>
                    <p className="table-tbody-text">{margin > 0 ? margin : '-'}</p>
                </div>
            ),
        },
        {
            title: (
                <div className=" table-thead-text rounded">
                    {t(`disputes.items.margin`, { ns: 'common' })}
                </div>
            ),
            dataIndex: 'frequency',
            key: 'frequency',
            render: (frequency: number) => (
                <div>
                    <p className="table-tbody-text">
                        {t(`disputes.items.frequency.${frequency}`, { ns: 'common' })}
                    </p>
                </div>
            ),
        },
        {
            title: (
                <div className=" table-thead-text rounded">
                    {t(`disputes.items.amount`, { ns: 'common' })}
                </div>
            ),
            dataIndex: 'total',
            key: 'total',
            render: (total: number) => (
                <div>
                    <p className="table-tbody-text">{formatPriceBaseOnCurrency(total)}</p>
                </div>
            ),
        },
    ];

    return (
        <>
            <div className={styles.headerColCustom}>
                <Title level={3}>{title}</Title>
                <div className="m-2">
                    <span className="cursor-pointer">
                        <TooltipWithCopy text={orderDetail.id} label="ID" />
                    </span>
                </div>
            </div>

            <div>
                <Row gutter={[24, 4]} align="middle">
                    <Col span={12}>
                        <Row justify="space-between">
                            <Text>{t('disputes.order_increment_id', { ns: 'common' })}</Text>
                            <Text strong>#{orderDetail.incrementId}</Text>
                        </Row>
                    </Col>

                    <Col span={12}>
                        <Row justify="space-between">
                            <Text>{t('disputes.grand_total', { ns: 'common' })}</Text>
                            <Text strong>
                                {formatPriceBaseOnCurrency(orderDetail.grandTotal ?? 0)}
                            </Text>
                        </Row>
                    </Col>

                    <Col span={12}>
                        <Row justify="space-between">
                            <Text>{t('disputes.total_order_items', { ns: 'common' })}</Text>
                            <Text strong>{orderDetail.items.length}</Text>
                        </Row>
                    </Col>
                </Row>

                <Tabs defaultActiveKey="1" tabBarGutter={24} style={{ marginTop: '24px' }}>
                    <TabPane
                        tab={
                            <span style={{ color: '#4CAF50' }}>
                                {t('disputes.order_items', { ns: 'common' })}
                            </span>
                        }
                        key="1"
                    >
                        <div className="overflow-hidden">
                            <div className="table-wrapper">
                                <Table
                                    dataSource={orderDetail.items}
                                    rowKey="id"
                                    columns={orderItems}
                                    scroll={{ y: '52vh', x: '800px' }}
                                />
                            </div>
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        </>
    );
};

export default DisputeOrderDetail;
