import { ExclamationCircleOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { DisputeResponseStateEnum } from '@enums/disputes.enum';
import { NReport } from '@interfaces/components/report';
import { useList, useNavigation } from '@refinedev/core';
import { convertToTitleCase, getLocalImageUrl } from '@utils/resource';
import { notification, Table, Tag, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { formatPriceBaseOnCurrency } from 'common/functions/format-price';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { v4 as uuidv4 } from 'uuid';

import { styleCardBrand, styleStatusState } from '..';
import s from './styles.module.scss';

export const DisputesInformation: React.FC<{
    filter: NReport.IFilter;
    timeZone: string;
}> = ({ filter, timeZone }) => {
    const { t } = useTranslation(['common']);
    const { push } = useNavigation();

    const [limit] = useState(10);
    const [offset, setOffset] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState<NReport.IDisputesReportDto[]>([]);

    const {
        data: fetchedData,
        isFetching,
        refetch: getDisputesReport,
    } = useList<NReport.IDisputesReportDto>({
        resource: 'v1/disputes',
        queryOptions: { enabled: false },
        config: {
            hasPagination: false,
            filters: [
                { field: 'dateRange', operator: 'eq', value: filter.dateRange },
                { field: 'offset', operator: 'eq', value: offset },
                { field: 'limit', operator: 'eq', value: limit },
                { field: 'timeZone', operator: 'eq', value: timeZone },
            ],
        },
    });

    const showDisputeDetail = (disputeId: string) => {
        if (!disputeId) return;
        push(`/wl/reports/disputes/show/${disputeId}`);
    };

    const fetchMoreData = async () => {
        if (hasMore) {
            setOffset((prevOffset) => prevOffset + limit);
            await getDisputesReport();
        }
    };

    const handleCopyCode = (code: string) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(code).then(
                () => {
                    notification.success({
                        message: '',
                        description: t('disputes.copy_clipboard_success', { ns: 'common' }),
                    });
                },
                () => {
                    notification.error({
                        message: '',
                        description: t('disputes.copy_clipboard_failed', { ns: 'common' }),
                    });
                },
            );
        }
    };

    useEffect(() => {
        setLoading(true);
        setOffset(1);
        getDisputesReport();
        setLoading(false);
    }, [filter.dateRange]);

    useEffect(() => {
        if (fetchedData?.data) {
            const isMoreData = fetchedData.data.length === limit;
            setHasMore(isMoreData);

            if (offset === 1) {
                setData(fetchedData.data);
            } else {
                setData((prevData) => [...prevData, ...fetchedData.data]);
            }

            setLoading(false);
        }
    }, [fetchedData]);

    useEffect(() => {
        getDisputesReport();
    }, [offset]);

    const TooltipWithCopy: React.FC<{ text: string; label: string }> = ({ text, label }) => {
        return (
            <Tooltip
                title={
                    <div className={s.customTooltip}>
                        {text}
                        <span className={s.copyCode} onClick={() => handleCopyCode(text)}>
                            {t('disputes.click_to_copy', { ns: 'common' })}
                        </span>
                    </div>
                }
                trigger={'hover'}
                placement="right"
                overlayClassName={s.customOverlay}
            >
                <Tag color="default">{label}</Tag>
            </Tooltip>
        );
    };

    const columns: ColumnsType<NReport.IDisputesReportDto> = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text: string) => <TooltipWithCopy text={text} label="ID" />,
        },
        {
            title: (
                <div className="table-thead-text rounded">
                    {t('disputes.created_at', { ns: 'common' })}
                </div>
            ),
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 120,
            render: (createdAt: string) => (
                <p className="table-tbody-text">
                    <div>
                        <div>{dayjs(createdAt).format('M/D/YYYY')}</div>
                        <div className={s.textDate}>{dayjs(createdAt).format('h:mm A')}</div>
                    </div>
                </p>
            ),
        },

        {
            title: (
                <div className="table-thead-text rounded">
                    {t('disputes.updated_at', { ns: 'common' })}
                </div>
            ),
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            width: 120,
            render: (updatedAt: string) => (
                <p className="table-tbody-text">
                    <div>
                        <div>{dayjs(updatedAt).format('M/D/YYYY')}</div>
                        <div className={s.textDate}>{dayjs(updatedAt).format('h:mm A')}</div>
                    </div>
                </p>
            ),
        },
        {
            title: (
                <div className="table-thead-text rounded justify-center">
                    {t('disputes.respond_within', { ns: 'common' })}
                </div>
            ),
            dataIndex: 'respondWithin',
            key: 'respondWithin',
            width: 150,
            render: (respondWithin: string, record: NReport.IDisputesReportDto) => {
                const _day = dayjs(respondWithin)
                    .startOf('day')
                    .diff(dayjs().startOf('day'), 'day');

                return (
                    <div className="flex flex-row items-center">
                        {record.responseState === DisputeResponseStateEnum.NEEDS_RESPONSE ? (
                            <>
                                {_day >= 0 ? (
                                    <>
                                        {_day < 7 && (
                                            <img
                                                src={'/assets/images/timeout.svg'}
                                                alt="edit"
                                                className="cursor-pointer ml-1 mr-2"
                                            />
                                        )}
                                        <span className="table-tbody-text text-center">
                                            {_day > 1 ? `${_day} days` : `${_day} day`}
                                        </span>
                                    </>
                                ) : (
                                    <Tag color="red" icon={<ExclamationCircleOutlined />}>
                                        {t('disputes.expired', { ns: 'common' })}
                                    </Tag>
                                )}
                            </>
                        ) : (
                            <>-</>
                        )}
                    </div>
                );
            },
        },
        {
            title: (
                <div className={`${s.headerColCustom} table-thead-text rounded`}>
                    <span> {t('disputes.buyer_name', { ns: 'common' })}</span>
                    <span className={s.textDate}> {t('disputes.email', { ns: 'common' })}</span>
                </div>
            ),
            dataIndex: 'buyerName',
            key: 'buyerName',
            render: (buyerName: string, record: NReport.IDisputesReportDto) => (
                <p className={`${s.bodyColCustom} table-tbody-text`}>
                    <div>
                        <div>{buyerName?.trim() ? buyerName : '-'}</div>
                        <div className={s.textDate}>{record.email ?? '-'}</div>
                    </div>
                </p>
            ),
        },
        {
            title: t('disputes.amount', { ns: 'common' }),
            dataIndex: 'amount',
            key: 'amount',

            render: (amount: number) => (
                <>
                    <span className="font-bold">{formatPriceBaseOnCurrency(amount)}</span>
                    <span>({t(`disputes.currency.usd`, { ns: 'common' })})</span>
                </>
            ),
        },

        {
            title: (
                <div className={`${s.headerColCustom} table-thead-text rounded`}>
                    <span> {t('disputes.state', { ns: 'common' })}</span>
                    <span className={s.textDate}> {t('disputes.reason', { ns: 'common' })}</span>
                </div>
            ),
            dataIndex: 'state',
            key: 'state',
            render: (state: string, record: NReport.IDisputesReportDto) => (
                <p className={`${s.bodyColCustom} table-tbody-text`}>
                    <div>
                        <div className="flex items-center">
                            <span className="pr-1">{styleStatusState(state)}</span>
                            {convertToTitleCase(state ?? '')}
                        </div>
                        <div className={s.textDate}>
                            {convertToTitleCase(record?.reason ?? '') ?? '-'}
                        </div>
                    </div>
                </p>
            ),
        },
        {
            title: (
                <div className={`${s.headerColCustom} table-thead-text rounded`}>
                    <span> {t('disputes.payment_instrument', { ns: 'common' })}</span>
                    <span className={s.textDate}>
                        {t('disputes.masked_number', { ns: 'common' })}
                    </span>
                </div>
            ),
            dataIndex: 'maskedNumber',
            key: 'maskedNumber',
            render: (maskedNumber: string, record: NReport.IDisputesReportDto) => {
                const masked = `${record.bin} •••••• ${record.lastFour}`;

                return (
                    <div className="table-tbody-text">
                        <div>{maskedNumber?.trim() ? maskedNumber : '-'}</div>
                        <div className="flex flex-row items-center">
                            <span className="pr-2">
                                {record?.brand && styleCardBrand(record?.brand)}
                            </span>
                            {masked}
                        </div>
                    </div>
                );
            },
        },

        {
            title: (
                <div className={`${s.headerColCustom} table-thead-text rounded`}>
                    <span> {t('disputes.instrument_type', { ns: 'common' })}</span>
                    <span className={s.textDate}>
                        {t('disputes.brand', { ns: 'common' })} /{' '}
                        {t('disputes.card_type', { ns: 'common' })}
                    </span>
                </div>
            ),
            dataIndex: 'brand',
            key: 'brand',
            width: 300,
            render: (brand: string, record: NReport.IDisputesReportDto) => {
                // record?.type = 'PAYMENT_CARD';
                const _type = record?.type?.includes('CARD')
                    ? convertToTitleCase(record?.type)?.split(' ')?.[1]
                    : convertToTitleCase(record?.type);
                return (
                    <p className={`${s.bodyColCustom} table-tbody-text`}>
                        <div>
                            <div>{_type}</div>
                            <div className={s.textDate}>
                                {convertToTitleCase(brand ?? '') ?? '-'} /{' '}
                                {convertToTitleCase(record?.cardType) ?? '-'}
                            </div>
                        </div>
                    </p>
                );
            },
        },

        {
            dataIndex: 'Action',
            key: 'Action',
            render: (__, current) => (
                <div className="text-center">
                    <InfoCircleTwoTone
                        alt="detail"
                        className="cursor-pointer"
                        style={{ fontSize: '24px' }}
                        onClick={() => showDisputeDetail(current.id)}
                    />
                </div>
            ),
            className: 'w-32',
        },
    ];

    return (
        <>
            <section className="order-statistic-container mt-5">
                <div className={s.tableWrapper} id="scrollableDiv">
                    <InfiniteScroll
                        dataLength={data.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<></>}
                        scrollableTarget="scrollableDiv"
                    >
                        <Table
                            dataSource={data}
                            columns={columns}
                            pagination={false}
                            loading={isFetching}
                            rowKey={() => uuidv4()}
                            scroll={{ x: '1600px', y: '100%' }}
                        />
                    </InfiniteScroll>
                </div>
            </section>
        </>
    );
};
