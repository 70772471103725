import './styles.scss';

import { QuoteStatus } from '@enums/quote-status.enum';
import { IInvoice } from '@interfaces/invoice';
import { IUser } from '@interfaces/user';
import { useDrawerForm, useModal } from '@refinedev/antd';
import {
    BaseKey,
    useCustomMutation,
    useGetIdentity,
    useIsAuthenticated,
    useNavigation,
    useOne,
} from '@refinedev/core';
import { FONT_INTER } from '@utils/fonts';
import { formatDate, formatPrice } from '@utils/resource';
import { Button, Col, notification, Row, Spin, Tag } from 'antd';
import { TOKEN_KEY } from 'authProvider';
import { UserTypeEnum } from 'enums/user-type.enum';
import { getEnvConfig } from 'getEnvConfig';
import jsPDF from 'jspdf';
import { PayoutContextProvider } from 'pages/internal-crm/context/payout-warning';
import { FinixPaymentModal } from 'pages/internal-crm/finix-payment-modal';
import qs from 'qs';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { maskPhone } from 'utils/string';

import { DataProviderNameEnum } from '../../../dataProvider';
import { ItemTypeEnum } from '../../../enums/item-type.enum';
import { ITenant } from '../../../interfaces/tenant';
import { TenantDetailCrmIndex } from '../tenant/detail';

export type FinixPaymentForm = {
    open: boolean;
    secret: string;
    transactionId: string;
    invoiceId: string;
};

export const InvoiceDetailsCrmIndex: React.FC = () => {
    const { t } = useTranslation(['quote', 'common']);
    const { id } = useParams();
    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;
    const { data: userIdentity } = useGetIdentity<IUser.IUserDto>();

    const isReseller = userIdentity?.userType === UserTypeEnum.RESELLER;

    const [loading, setLoading] = useState<boolean>(false);
    const { push, goBack } = useNavigation();
    const { isSuccess: isAuthenticated } = useIsAuthenticated();
    const componentToPrint = useRef<any>();

    const searchQuery = window?.location?.search
        ? new URLSearchParams(window.location.search)
        : null;
    const paymentStatus = searchQuery ? searchQuery.get('redirect_status') : '';
    const invoiceQueryString = searchQuery ? searchQuery.get('invoiceFilter') : '';
    const invoiceFilter = invoiceQueryString ? qs.stringify(JSON.parse(invoiceQueryString)) : '';
    const contactId = searchQuery ? searchQuery.get('contactId') : '';
    let quoteId: string | null = null;
    quoteId = searchQuery ? searchQuery.get('quoteId') : '';
    const apiUrl = getEnvConfig.INTERNAL_CRM_API_URL;

    const [showFinixPaymentForm, setFinixPaymentForm] = useState<FinixPaymentForm>({
        open: false,
        secret: '',
        transactionId: '',
        invoiceId: '',
    });

    const {
        data: invoiceData,
        refetch: invoiceRefetch,
        isLoading,
        error,
    } = useOne<IInvoice.InvoiceDetails>({
        dataProviderName: 'internalCrm',
        resource: 'v1/invoices',
        id: id as BaseKey,
        queryOptions: {
            enabled: false,
        },
        errorNotification: {
            type: 'error',
            message: t('quotes.error.invoice_has_expired'),
        },
    });

    const { mutate } = useCustomMutation<any>();

    let token: string | null = null;
    const tokenKey = localStorage.getItem(TOKEN_KEY);
    const urlParams = new URLSearchParams(window.location.search);
    token = urlParams.get('token');
    quoteId = urlParams.get('quoteId');

    useEffect(() => {
        if (error) {
            const { statusCode } = error as { statusCode: number };
            if (statusCode === 404) {
                push('/404');
            }
        }
    }, [error]);

    if (!isAuthenticated) {
        if (!token && !tokenKey) {
            push('/404');
        } else {
            invoiceRefetch();
        }
    } else {
        invoiceRefetch();
    }

    const invoice: IInvoice.InvoiceDetails | undefined = invoiceData?.data;

    const getTotalPrice = () => {
        if (invoiceData?.data?.items) {
            let total: any = 0;
            // eslint-disable-next-line no-unsafe-optional-chaining
            for (const item of invoiceData?.data?.items) {
                total += item.total;
            }

            return total;
        }
        return 0;
    };

    const createPayment = () => {
        setLoading(true);
        mutate(
            {
                url: `${apiUrl}/v1/invoices/${id}/payment-intent`,
                method: 'post',
                values: {},
            },
            {
                onError: (error) => {
                    // An error happened!
                    setLoading(false);
                    console.error(error);
                },
                onSuccess: (data) => {
                    const result = data?.data;
                    if (result?.data?.clientSecret) {
                        onChangeFinixPaymentForm({
                            open: true,
                            secret: result.data.clientSecret,
                            transactionId: result.data.paymentIntentId ?? '',
                            invoiceId: result.data.invoiceId ?? '',
                        });
                    }
                    setLoading(false);
                },
            },
        );
    };

    const onChangeFinixPaymentForm = (value: FinixPaymentForm) => {
        setFinixPaymentForm(value);
    };

    const onPayClick = () => {
        setLoading(true);
        createPayment();
    };

    const handleDownload = useReactToPrint({
        content: () => componentToPrint.current,
        copyStyles: true,
        pageStyle: `
        @media all {
            .no-print {
                display: none!important;
            }
            .no-space {
                letter-spacing: 0.01px;
            }
        }
        `,
        print: async (printIframe: any) => {
            const document = printIframe.contentDocument;
            if (document) {
                const html = document.getElementsByTagName('html')[0];
                const customPrintPdf = html.querySelector('.custom-print-pdf');
                customPrintPdf.style.marginTop = '15px';
                customPrintPdf.style.paddingBottom = '12px';
                html.style.width = '900px';

                const doc = new jsPDF('p', 'px');
                doc.addFileToVFS('inter.ttf', FONT_INTER);
                doc.addFont('inter.ttf', 'inter', 'normal');
                doc.setFont('inter', 'normal');
                doc.html(html, {
                    html2canvas: {
                        scale: 0.5,
                    },
                    async callback(doc) {
                        await doc.save(`Invoice #${invoice?.incrementId}`);
                    },
                });
            }
        },
    });

    const isPlanned = (invoice?.items || []).some(
        (invoiceItem) => (invoiceItem?.percentage ?? 0) > 0,
    );

    const onCancelClick = () => {
        if (isAuthenticated) {
            if (quoteId) {
                push(`/sale/quotes/show/${quoteId}`);
            } else {
                if (invoiceFilter) {
                    push(`/sale/invoices?${invoiceFilter}`);
                } else if (contactId) {
                    goBack();
                } else {
                    push('/sale/invoices');
                }
            }
        } else {
            if (quoteId) {
                push(`/quotes/public/${quoteId}?token=${token}`);
            }
        }
    };

    const isPartnerInvoice =
        !invoice?.contactId &&
        userIdentity?.userType &&
        // user is partner type
        [UserTypeEnum.WHITELABEL, UserTypeEnum.AFFILIATE_PARTNER].includes(
            userIdentity?.userType,
        ) &&
        // invoice was created for partner
        (invoice?.userId === userIdentity?.id ||
            // staff of wl partner
            (!userIdentity?.isTenantOwner &&
                userIdentity.userType === UserTypeEnum.WHITELABEL &&
                invoice?.tenantId === userIdentity.tenantId));

    const mappingColorInvoiceStatus: { [key in QuoteStatus]?: string } = {
        [QuoteStatus.Approved]: 'green',
        [QuoteStatus.Paid]: 'green',
        [QuoteStatus.Pending]: 'blue',
        [QuoteStatus.Rejected]: 'red',
    };
    const isPublic =
        !isAuthenticated ||
        // invoice belong to contact
        invoiceData?.data?.contactUserId === userIdentity?.id ||
        // createdBy of invoice is not tenant user or invoice userId is current user
        // wl partner and staffs can pay invoices of each other
        (!invoiceData?.data?.contactId &&
            ((userIdentity?.userType &&
                [UserTypeEnum.WHITELABEL, UserTypeEnum.AFFILIATE_PARTNER].includes(
                    userIdentity.userType,
                )) ||
                invoiceData?.data?.userId === userIdentity?.id));
    const canPay = () => {
        return invoice?.status === QuoteStatus.Pending && isPublic;
    };

    const showTenantModal =
        invoice?.status === QuoteStatus.Paid &&
        invoice?.items.some((item) => item.itemType === ItemTypeEnum.MODULE && !item.targetId) &&
        isPublic;

    const handleShowTenantModal = () => {
        if (showTenantModal) {
            createFormProps.form.setFieldsValue({
                email: invoice?.contactEmail,
                name: invoice?.contactName,
                firstName: invoice?.contactFirstName,
                lastName: invoice?.contactLastName,
                phone: maskPhone(invoice?.contactPhone || ''),
                skipOnboarding: true,
            });
            showModalCreateTenant();
        }
    };

    const { formProps: createFormProps, saveButtonProps: createSaveButtonProps } =
        useDrawerForm<ITenant.ITenantInfor>({
            dataProviderName,
            action: 'create',
            resource: `v1/payments/create-new-tenant-subscription`,
            successNotification: { message: 'Successfully created', type: 'success' },
            redirect: false,
            onMutationSuccess: (data) => {
                createFormProps.form.resetFields();
                closeModalCreateTenant();
            },
            onMutationError: (error) => {
                notification.error({
                    message: error?.message
                        ? t(`settings.${error?.response?.data?.error}`, { ns: 'common' })
                        : t('users.create_user_failed', { ns: 'common' }),
                    type: 'error',
                });
            },
        });

    const onHandleFinixPaymentSuccess = async () => {
        handleShowTenantModal();
    };

    const { modalProps, show: showModalCreateTenant, close: closeModalCreateTenant } = useModal();
    const hasModule = invoice?.items?.some((item) => item.serviceId === null);
    return (
        <PayoutContextProvider>
            <>
                <TenantDetailCrmIndex
                    modalProps={modalProps}
                    formProps={createFormProps}
                    saveButtonProps={createSaveButtonProps}
                    invoiceId={invoice?.id || ''}
                    disabledSkipOnboarding
                />

                {!isLoading && !error && (
                    <section
                        className="invoice-container invoice-detail"
                        ref={(el) => (componentToPrint.current = el)}
                    >
                        <div className="block-heading">
                            <div className="date-wrapper">
                                <div className="flex justify-start items-center">
                                    <span>
                                        {t('quotes.heading.invoiceDetails')}: {invoice?.incrementId}
                                    </span>

                                    {paymentStatus === 'succeeded' ? (
                                        <Tag className="ml-4 custom-print-pdf" color="green">
                                            {t('quotes.status.' + QuoteStatus.Paid)}
                                        </Tag>
                                    ) : null}

                                    {invoice?.status && paymentStatus !== 'succeeded' ? (
                                        <Tag
                                            className="ml-4 custom-print-pdf"
                                            color={
                                                mappingColorInvoiceStatus[invoice.status] ??
                                                'default'
                                            }
                                        >
                                            {t('quotes.status.' + invoice.status)}
                                        </Tag>
                                    ) : null}
                                </div>
                                <div className="date-container">
                                    <span className="date-title">
                                        {t('quotes.fields.paymentDate.label')} -{' '}
                                        {formatDate(invoice?.date, 'MMM DD, YYYY')}
                                    </span>
                                    <span className="date-title">
                                        {t('quotes.fields.paymentDueDate.label')} -{' '}
                                        {formatDate(invoice?.dueDate, 'MMM DD, YYYY')}
                                    </span>
                                </div>
                            </div>
                            <div className="ml-auto">
                                <Button
                                    type="link"
                                    icon={<img src="/images/icons/download.svg" />}
                                    className="no-print mr-3"
                                    onClick={handleDownload}
                                />
                                <ReactToPrint
                                    copyStyles={true}
                                    pageStyle={`
                                @page { margin: 0; }
                                @media all {
                                    .no-print {
                                        display: none!important;
                                    }
                                }
                            `}
                                    trigger={() => (
                                        <Button
                                            type="link"
                                            icon={<img src="/images/icons/print.svg" />}
                                            className="no-print"
                                        />
                                    )}
                                    content={() => componentToPrint.current}
                                />
                            </div>
                        </div>
                        {invoice?.reason ? (
                            <div className="details-section">
                                <div className="section-header">
                                    {t('invoice.change_status_reason')}
                                </div>
                                <Row gutter={20}>
                                    <Col md={24}>
                                        <pre>{invoice.reason}</pre>
                                    </Col>
                                </Row>
                            </div>
                        ) : null}
                        {invoice?.description && (
                            <div className="details-section">
                                <div className="section-header">
                                    {t('quotes.fields.description.label')}
                                </div>
                                <Row gutter={20}>
                                    <Col md={24}>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: invoice?.description as string,
                                            }}
                                        ></div>
                                    </Col>
                                </Row>
                            </div>
                        )}
                        {/* Invoice created by partner would not show this section information to partner */}
                        {!isPartnerInvoice ? (
                            <div className="details-section">
                                <Row gutter={20}>
                                    <Col md={12} className="user-info-col">
                                        <div className="user-info">
                                            <div className="info-header">
                                                {t('quotes.heading.createdBy')}
                                            </div>
                                            <div className="name">
                                                {(invoice?.createdByUser?.firstName ?? '') +
                                                    ' ' +
                                                    (invoice?.createdByUser?.lastName ?? '')}
                                            </div>
                                            <div className="no-space">
                                                {invoice?.createdByUser?.email ?? ''}
                                            </div>
                                            <div className="no-space">
                                                {invoice?.createdByUser?.phone
                                                    ? maskPhone(invoice?.createdByUser?.phone)
                                                    : ''}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={12} className="user-info-col">
                                        <div className="user-info text-right">
                                            <div className="info-header">
                                                {t('quotes.heading.for')}
                                            </div>
                                            <div className="name">{invoice?.contactName}</div>
                                            <div className="no-space">{invoice?.contactEmail}</div>
                                            <div className="no-space">
                                                {invoice?.contactPhone
                                                    ? maskPhone(invoice?.contactPhone)
                                                    : ''}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        ) : null}
                        <div className="details-section invoice-items-table">
                            <div className="section-header">{t('quotes.heading.itemDetails')}</div>
                            <Row className="item-list">
                                <Col span={24}>
                                    <Row className="table-header">
                                        <Col span={4}>{t('quotes.fields.item.label')}</Col>
                                        <Col span={3}>{t('quotes.fields.unitPrice.label')}</Col>
                                        <Col span={3}>{t('quotes.fields.qty.label')}</Col>
                                        <Col span={3}>{t('quotes.fields.taxAmount.label')}</Col>
                                        <Col span={4}>{t('quotes.fields.frequency.label')}</Col>
                                        {hasModule && (
                                            <Col span={4}>{t('quotes.fields.tenant.label')}</Col>
                                        )}
                                        {isPlanned ? (
                                            <Col span={4}>
                                                {t('quotes.fields.percentage.label')}
                                            </Col>
                                        ) : null}
                                        <Col span={3}>{t('quotes.fields.total.label')}</Col>
                                    </Row>
                                    {invoice &&
                                        invoice?.items?.length > 0 &&
                                        invoice?.items?.map((item) => {
                                            const isItemModule = item.serviceId === null;

                                            return (
                                                <Row
                                                    className="list-product-item"
                                                    key={item?.productName + item?.id}
                                                >
                                                    <Col span={4}>{item.productName}</Col>
                                                    <Col span={3}>
                                                        <span className="unit-product">
                                                            {formatPrice(
                                                                item?.unitPrice
                                                                    ? item?.unitPrice
                                                                    : item.total / item.qty,
                                                            )}

                                                            {userIdentity?.userType !==
                                                                UserTypeEnum.CLIENT &&
                                                            item.type === UserTypeEnum.WHITELABEL &&
                                                            item?.charge > 0 ? (
                                                                <span>
                                                                    <b>
                                                                        {t(
                                                                            'quotes.fields.service_fee.label',
                                                                        )}
                                                                        :{' '}
                                                                    </b>
                                                                    {item.charge}%
                                                                </span>
                                                            ) : null}
                                                        </span>
                                                    </Col>
                                                    <Col span={3}>{item.qty}</Col>

                                                    <Col span={3}>
                                                        {item.taxPercentage
                                                            ? `${item.taxPercentage}%`
                                                            : ''}
                                                    </Col>
                                                    <Col span={4}>
                                                        {t('quotes.frequency.' + item.frequency)}
                                                    </Col>
                                                    {hasModule && (
                                                        <Col
                                                            title={item.tenantName}
                                                            className={`truncate ${
                                                                !isItemModule && '!pl-9'
                                                            }`}
                                                            span={4}
                                                        >
                                                            {isItemModule ? item.tenantName : '--'}
                                                        </Col>
                                                    )}
                                                    {isPlanned ? (
                                                        <Col span={4}>
                                                            {item?.percentage
                                                                ? item?.percentage
                                                                : ''}
                                                        </Col>
                                                    ) : null}

                                                    <Col span={3}>{formatPrice(item.total)}</Col>
                                                </Row>
                                            );
                                        })}
                                </Col>
                            </Row>

                            <div className="sum-total flex">
                                <span>{t('quotes.heading.totalPrice')}</span>
                                <span className="ml-auto">
                                    {formatPrice(invoiceData?.data.amount)}
                                </span>
                            </div>
                            {isReseller && (
                                <div className="sum-total flex">
                                    <span>{t('quotes.heading.earnings')}</span>
                                    <span className="ml-auto">
                                        {formatPrice(invoiceData?.data.saleEarning || 0)}
                                    </span>
                                </div>
                            )}
                        </div>

                        <div className="invoice-items-list">
                            {invoice &&
                                invoice?.items?.length > 0 &&
                                invoice?.items?.map((item) => {
                                    return (
                                        <div className="p-6" key={item?.productName + item?.id}>
                                            <Row
                                                key={`name_${item.id}`}
                                                className="list-product-item"
                                            >
                                                <Col span={12} className="title">
                                                    <span>{t('quotes.fields.item.label')}</span>
                                                </Col>
                                                <Col span={12} className="value">
                                                    <span>{item.productName}</span>
                                                </Col>
                                            </Row>
                                            <Row
                                                key={`title_${item.id}`}
                                                className="list-product-item"
                                            >
                                                <Col span={12} className="title">
                                                    <span>
                                                        {t('quotes.fields.unitPrice.label')}
                                                    </span>
                                                </Col>
                                                <Col span={12} className="value">
                                                    <span>
                                                        {formatPrice(item.total / item.qty)}
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Row
                                                key={`qty_${item.id}`}
                                                className="list-product-item"
                                            >
                                                <Col span={12} className="title">
                                                    <span>{t('quotes.fields.qty.label')}</span>
                                                </Col>
                                                <Col span={12} className="value">
                                                    <span>{item.qty}</span>
                                                </Col>
                                            </Row>
                                            <Row
                                                key={`frequency_${item.id}`}
                                                className="list-product-item"
                                            >
                                                <Col span={12} className="title">
                                                    <span>
                                                        {t('quotes.fields.frequency.label')}
                                                    </span>
                                                </Col>
                                                <Col span={12} className="value">
                                                    <span>
                                                        {t('quotes.frequency.' + item.frequency)}
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Row
                                                key={`total_${item.id}`}
                                                className="list-product-item"
                                            >
                                                <Col span={12} className="title">
                                                    <span>{t('quotes.fields.total.label')}</span>
                                                </Col>
                                                <Col span={12} className="value">
                                                    <span>{formatPrice(item.total)}</span>
                                                </Col>
                                            </Row>
                                        </div>
                                    );
                                })}
                            <div className="sum-total flex p-6">
                                <span className="sum-total-title">
                                    {t('quotes.heading.totalPrice')}
                                </span>
                                <span className="ml-auto sum-total-value">
                                    {formatPrice(getTotalPrice())}
                                </span>
                            </div>
                        </div>
                        {invoice?.termsAndConditions && (
                            <div className="details-section">
                                <div className="section-header">{t('quotes.fields.tc.label')}</div>
                                <Row gutter={20}>
                                    <Col md={24}>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: invoice?.termsAndConditions as string,
                                            }}
                                        ></div>
                                    </Col>
                                </Row>
                            </div>
                        )}
                        <div className="details-section no-print  detail-actions">
                            <div className="flex flex-row-reverse">
                                {paymentStatus !== 'succeeded' ? (
                                    canPay() ? (
                                        <>
                                            <Button
                                                type="primary"
                                                className="ml-4"
                                                loading={loading}
                                                onClick={onPayClick}
                                            >
                                                {t('quotes.actions.pay')}
                                            </Button>
                                        </>
                                    ) : null
                                ) : null}{' '}
                                {showTenantModal && (
                                    <Button
                                        type="primary"
                                        className="ml-4"
                                        onClick={() => handleShowTenantModal()}
                                        loading={loading}
                                    >
                                        {t('quotes.actions.createTenant')}
                                    </Button>
                                )}
                                <Button onClick={onCancelClick}>
                                    {t('quotes.actions.cancel')}
                                </Button>
                            </div>
                        </div>
                    </section>
                )}
                {(isLoading || !!error) && <Spin spinning={true}></Spin>}

                {showFinixPaymentForm?.open && (
                    <FinixPaymentModal
                        showFinixPaymentForm={showFinixPaymentForm}
                        onChangeFinixPaymentForm={onChangeFinixPaymentForm}
                        onHandleFinixPaymentSuccess={onHandleFinixPaymentSuccess}
                    />
                )}
            </>
        </PayoutContextProvider>
    );
};
